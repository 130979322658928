//CONTACT HOMEPAGE STYLESHEET

#contactme {
  pointer-events: auto;
  //height: 100%;

  .readmore.white img {transform: rotate(0) !important;}

  .section-image {

    .img {
      background-color: $white !important;
      height: auto;
      overflow-y: hidden;
    }
  }


  .header.white {
    pointer-events: none;
  }

  #error-message {
    padding-left: 15px;
    color: $warning-color;

    &.positive {
      color: $success-color
    }
  }

  form {
    //max-width: $global-width - 10rem;
    margin: 0 auto;
    $line-height: 50px;

    .textfield {
      padding-left: 15px;
      position: relative;

      .input-wrapper {
        position: relative;
        overflow: hidden;
      }

      .contact-placeholder {
        pointer-events: none;
        position: absolute;
        top: 0;
        opacity: 1;
        font-family: $button-font-family;
        text-transform: uppercase;
        @extend .cbutton;
        background-color: transparent;
        padding: 0 20px;
        color: rgba($white, 0.8);
        line-height: $line-height;
        transition: top 0.2s $timingcurve;
      }

      input,
      textarea {
        color: $white;
        text-indent: 10px;
        border: none;
        background-color: $blue2;
        transition: background-color 0.3s $timingcurve;
        &:focus, &:hover {
          background-color: $blue3;
        }

        &.error {
          border-left: 10px solid $warning-color;
          text-indent: 0;
        }
      }

      input {
        height: $line-height;
      }

      textarea {
        min-height: 200px;
        min-width: 100%;
      }

      &.active {
        .contact-placeholder {
          top: -40px
        }
      }
    }

    .submitbutton {
      //padding: $label-offset 20px 0;

      input {
        width: 100%;
        height: $line-height;
        padding: 0;
      }
    }

    &.sent  {
      input, textarea {
        background-color: $medium-gray !important;
        pointer-events: none !important;
      }
    }
  }

  @include breakpoint(medium only) {
    height: 400px;
  }

  @include breakpoint(small only) {
    flex-direction: column;
    .section-text p {
      margin: 0 15px 15px;
    }
    .header.blue {
      display: block;
      text-align: center;
    }
    .section-image {
      top: -80px;
      height: auto;
    }
    .centerme {
      position: relative;
      top: 480px;
      z-index: 1;
    }

    #error-message {
      margin-top: -5px;
    }

    form .textfield {
      padding: 0 15px;

      .contact-placeholder,
      input,
      textarea {
        font-size: 80%;
      }
    }
  }
}
