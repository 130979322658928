.pf-label {
  position: relative;
  z-index: 2;
  top: 10px;
  left: 10px;
  display: inline-block;
  vertical-align: top;
  background-color: $white;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0 5px;
  letter-spacing: 2px;
}

.pf-title {
  text-align: center;
  line-height: 90%;
  color: $white;
}

.pf-subtitle {
  font: italic normal bold 100% $body-font-family;
}

.pf-item {
  background-size: auto 100%;
  background-position: center;
  transition: background-size 0.2s $timingcurve;

  &:hover {
    background-size: auto 110%;
  }
}

.orbitors {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  .orbitor div {
    height: 4px;
    width: 40px;
    background-color: rgba($blue4,0.5);
    margin: 20px 5px;
  }

  .orbitor.itsme div {
    background-color: $blue3;
  }
}

#portfolio {
  $expand-time: 0.5s;
  max-width: none;
  margin-right: -1*$content-margin;
  margin-top: 0;

  .section-text {
    @include breakpoint(large) {
      padding-left: 10%;
    }
  }

  .header-anchor {
    //min-width: 400px;
    left: 0;
    right: 0;
    margin-left: 40%;
    transition: $ahover-spd $timingcurve !important;
  }

  .orbit-slider {
    display: flex;
    flex-direction: row;
    width: auto;

    .pf-item {
      position: relative;
      @extend .img;
      min-width: calc(50vw - 18.5px); //40 / aantal
      @media only screen and (min-width: 1400px) {
        min-width: calc(33vw - 13.5px);
      }
      @media only screen and (min-width: 1800px) {
        min-width: calc(25vw - 20px);
      }
      margin-right: 20px;
      margin-left: 0; //-40px
    }
  }

  .pf-label {
    visibility: hidden;
  }

  .pf-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(black,0.5) 0%, rgba(black,0) 35%);
    background-color: rgba($black,0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    transition: background-color $ahover-spd $timingcurve;
    text-shadow: 0 0 3px $black;
    color: $white;
    padding-bottom: 5%;

    .cbutton {
      position: absolute;
      padding-top: 45%;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      opacity: 0;
      transition: opacity $ahover-spd $timingcurve;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba($blue1,0.6);

      .cbutton {
        opacity: 1;
      }
    }
  }

  &.hover .header-anchor {
    margin-left: calc(40% - 0.4*40px);
  }

  &.expended {
    .pf-item {
      pointer-events: auto;
    }

    .pf-label {
      visibility: visible;
    }

    .header.white {
      pointer-events: none;
      visibility: hidden;
    }

    .header-anchor {
      margin-left: 0;
    }

    .pf-overlay:not(:hover) {
      background-color: rgba($blue1,0);
    }

    .header,
    p:not(.pf-subtitle) {
      transition: opacity $expand-time $timingcurve;
      opacity: 0;
    }

    .section-image {
      pointer-events: auto;
      padding-left: 0;
      left: -1*$content-margin;

      .readmore.white.cbutton {
        left: 0;
        pointer-events: auto;

        img {
          transform: scaleX(-1);
        }
      }
    }

    &.hover {
      .section-image {
        margin-left: 0;
      }
    }

    .readmore.blue.cbutton {
      background-color: $white;
    }
  }

  .orbit-slider-nav {
    position: absolute;
    left: -1*$content-margin;
    right: 0;
    height: 100%;

    .arrows {
      height: 100%;
      width: 100%;
      overflow-x: hidden;

      .arrow-left {
        float: left;
        //background: linear-gradient(to right,rgba($black,0.6),rgba($black,0));
        &.disable {
          left: -40px;
        }
      }

      .arrow-right {
        float: right;
        //background: linear-gradient(to left,rgba($black,0.6),rgba($black,0));
        &.disable {
          left: 40px;
        }
      }

      div {
        cursor: pointer;
        position: relative;
        background-color: $white;
        z-index: 100;
        color: $blue2;
        box-shadow: 2px 3px 4px rgba(0,0,0,.25);
        font-size: 2rem;
        top: calc(50% - 20px);
        height: 40px;
        width: 40px;
        left: 0;
        transition: left 0.2s $timingcurve;
        padding: 5px 12px;

        img {
          position: absolute;
          height: 30px;
        }
      }
    }
  }
}

#portfolio-small {
  overflow: hidden;

  .section-text p {
    margin-bottom: 20px;
  }

  .section-image2 {
    width: 85%;
    height: auto;
    margin: 0 auto;
  }

  .orbit-slider {
    display: flex;
    flex-direction: row;
    width: 100%;
    transition: margin 0.3s $timingcurve;
  }

  .pf-item {
    position: relative;
    @extend .img;
    overflow: visible;
    margin-right: 15px;
    height: 70vw;
    margin-bottom: 100px;
    margin-top: -20px;
    min-width: 100%;
  }

  .pf-overlay {
    //position: relative;
    margin-top: 75vw;
    text-align: center;
  }

  .cbutton {
    position: absolute;
    top: 0;
    bottom: -120px;
    right: 0;
    left: 0;
    opacity: 0;
  }

  .pf-title {
    color: $blue1;
  }

  .pf-subtitle {
    margin: 0;
  }

  .orbit-slider-nav {
    position: relative;
    width: 100%;
    height: auto;
    top: calc(70vw + 20px);
  }

  .orbitor div {
    margin: 10px;
    width: 10px;
  }
}
