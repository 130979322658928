.portfoliopages {
  .hero,
  .hero .hero-image {
    height: 50vw;
    max-height: 500px;
  }

  .buttongroup {
    display: flex;
    justify-content: center;

    .cbutton {
      margin: 10px;
      overflow: hidden;
    }

    .cbutton img {
      transition: 0.3s $timingcurve;
    }

    .cbutton.outline:hover img {
      transform: translateX(-100px);
    }

    .cbutton:hover img {
      transform: translateY(-100px);
    }
  }

  .parallaxt {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(-1px) scale(2);
  }

  .hero .boxshadow {
    background: linear-gradient(to bottom, rgba(black,0) 95%, rgba(black,0.3) 100%);
    transform: translateZ(0);
  }

  .hero-overlay {
    position: absolute;
    top: $content-margin;
    left: $content-margin;
    height: 50vw;

    .cbutton {
      transition: all 0.3s $timingcurve;
      background-color: $white;
      color: $primary-color;
      box-shadow: 0 0 10px rgba($blue3,0.3); //, inset 0 0 10px rgba(black,0.2);
      //text-shadow: 0 0 10px rgba(black,0.2);
      &:hover {
        padding-left: 50px;
        //background-color: transparent;
      }
    }
  }

  .side-detail {
    background-color: $light-gray;
    position: sticky;
    top: 30px;
    color: $black;
    margin: 0 $content-margin 30px 0;
    padding: 20px;
    overflow-x: hidden;

    .title {
      text-align: center;
      padding-bottom: 10px;
      margin-bottom: 20px;
      //color: $black;
      line-height: 100%;
      //border-bottom: 2px solid rgba($black,0.9);
    }

    .detail {
      display: flex;
      margin: 10px 0;
      flex-wrap: nowrap;

      a,
      a:visited {
        width: 100%;
        display: inline-block;
        //margin-bottom: 10px;
        color: $black;
        word-break: break-all;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .detail .left {
      max-height: 30px;
      max-width: 30px;
      height: 100%;
      width: 100%;
      margin: 0 7% 10px 2%;
    }

    .detail .right {
      margin-top: 4px;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid $medium-gray;

      * {
        margin-bottom: 10px;
      }
    }

    .detail .right.lastitem {
      padding-bottom: 0;
      border: none;
    }

    .skillbar {
      margin-bottom: 15px;
    }
  }
  @include breakpoint(small only) {
    .side-detail {
      top: 0;
      position: relative;
      padding: 20px 12px 7px 7px;
      margin: 0 auto 20px;
      max-width: calc(350px + 10vw);
      overflow-y: hidden;
      height: auto;
      max-height: 100%;
      transition: 0.5s $timingcurve;
      //border-bottom: 1px solid $gray1;
      //margin-bottom: 20px;
      //font-size: 80%;
      .detail {
        margin: 5px;
      }

      .detail .left {
        margin: 0 5% 20px;
        min-height: 25px;
        min-width: 25px;
      }

      .detail .right .skillbar {
        margin-bottom: 10px;
      }

      .detail .right.lastitem {
        padding-bottom: 40px;
      }

      &.collapse {
        max-height: 200px;

        .detail {
          //display: none;
        }

        .collapsebutton .showless {
          display: none;
        }

        .collapsebutton .showmore {
          display: inline-block;
        }
      }
    }

    .collapsebutton {
      z-index: 1;
      width: 100%;
      height: 80px;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 40px 5px 5px;
      background: linear-gradient(to bottom, rgba($light-gray,0), rgba($light-gray,1) 50%);
      text-align: center;

      a {
        //margin: auto;
        //@include buttonscaler(0.7);
      }

      .showmore {
        display: none;
      }
    }

    .buttongroup {
      flex-direction: column-reverse;
    }
  }
}
