.masonry {
  position: relative;
  @include breakpoint($global-width) {
    //left: calc(-1* (50vw - 600px));
  }
  //width: 100vw;
  margin: 10px -10px;

  img {
    //transform: translate(0,0);
    padding: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .gallery-item {
    cursor: zoom-in;
  }

  .videowrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
  }

  .videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 8px;
  }
  @include breakpoint(small only) {
    img, .videowrapper {
      object-fit: contain;
      max-height: 300px;
    }
  }
}
