@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300i,400|Roboto:300|Titillium+Web:400,600i");
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.flyin {
  opacity: 0; }
  .flyin:not(.fadeonly) {
    transform: translateY(100px); }

.flyin-activated {
  opacity: 1;
  transform: translateY(0px);
  transition: transform 0.8s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.8s cubic-bezier(0.34, 0.01, 0.23, 0.98); }

.clickthru {
  pointer-events: none; }

.cbutton, #contactme form .textfield .contact-placeholder {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: "Roboto", sans-serif;
  padding: 1em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: all 0.25s ease-out;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #0b375b;
  color: #e9e9e9;
  text-transform: uppercase;
  transition-property: background-color, letter-spacing, color, padding;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.34, 0.01, 0.23, 0.98);
  white-space: nowrap;
  font-size: 1rem;
  letter-spacing: 8px;
  text-indent: 2px;
  padding: 0.8em 1em; }
  [data-whatinput='mouse'] .cbutton, [data-whatinput='mouse'] #contactme form .textfield .contact-placeholder, #contactme form .textfield [data-whatinput='mouse'] .contact-placeholder {
    outline: 0; }
  .cbutton:hover, #contactme form .textfield .contact-placeholder:hover, .cbutton:focus, #contactme form .textfield .contact-placeholder:focus {
    background-color: #092f4d;
    color: #e9e9e9; }
  @media print, screen and (min-width: 64em) {
    .cbutton.large, #contactme form .textfield .large.contact-placeholder {
      font-size: 1.25rem;
      letter-spacing: 10px;
      text-indent: 2.5px;
      padding: 1em 1.25em; } }
  @media screen and (max-width: 63.9375em) {
    .cbutton, #contactme form .textfield .contact-placeholder {
      font-size: 0.9rem;
      letter-spacing: 7.2px;
      text-indent: 1.8px;
      padding: 0.72em 0.9em; }
      .cbutton.large, #contactme form .textfield .large.contact-placeholder {
        font-size: 1.2rem;
        letter-spacing: 9.6px;
        text-indent: 2.4px;
        padding: 0.96em 1.2em; } }
  .cbutton.outline, #contactme form .textfield .outline.contact-placeholder {
    background-color: transparent;
    color: #e9e9e9;
    border: 1px solid #e9e9e9; }
    .cbutton.outline.inverted, #contactme form .textfield .outline.inverted.contact-placeholder {
      color: #0b375b;
      border: 1px solid #0b375b; }
  .cbutton:hover, #contactme form .textfield .contact-placeholder:hover {
    background-color: #174b70;
    color: #e9e9e9; }
    .cbutton:hover.inverted, #contactme form .textfield .contact-placeholder:hover.inverted {
      color: #e9e9e9; }
  .cbutton img, #contactme form .textfield .contact-placeholder img {
    margin-right: 10px;
    margin-top: -5px;
    height: 15px;
    transform: scale(1.5); }

.pagebg {
  background-color: #e9e9e9; }

.test {
  border: 2px dashed red; }

.h-alt {
  font-weight: 400;
  font-style: normal;
  font-family: "Open Sans", sans-serif; }

h5 {
  font-style: normal !important;
  font-weight: 400 !important; }

@keyframes skill-fillbar {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

.tag-font p, .tag-font h5 {
  font-size: 90%;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.9;
  margin: 0 0 5px;
  font-family: "Open Sans", sans-serif;
  line-height: 120%; }
  @media screen and (max-width: 63.9375em) {
    .tag-font p, .tag-font h5 {
      font-size: 80%; } }

.skillbar {
  height: 6px;
  margin: 0 0 12px;
  background-color: rgba(48, 48, 48, 0.2);
  overflow-x: hidden; }
  .skillbar .fill {
    height: 100%;
    background-color: rgba(70, 108, 144, 0.7);
    border-right: 5px solid #0b375b; }
  .skillbar.element-out .fill {
    transform: translateX(-100%); }
  .skillbar.element-in .fill {
    animation: skill-fillbar 1.5s cubic-bezier(0.29, 0.59, 0.34, 1); }

.skillpie {
  margin: 0 4%;
  opacity: 1;
  position: relative; }
  .skillpie svg {
    width: 100%;
    transform: rotate(-90deg); }
    .skillpie svg .fill {
      filter: drop-shadow(0 0 3px #cacaca); }
    .skillpie svg .stroke {
      stroke-dasharray: 283 283;
      stroke-width: 8px;
      transition: stroke-dashoffset 2s cubic-bezier(0.34, 0.01, 0.23, 0.98);
      transition-delay: 1s; }
  .skillpie img {
    padding: 20%;
    height: 95%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0; }

.element-out .skillpie svg .stroke {
  stroke-dashoffset: 252;
  transition: stroke-dashoffset 2s cubic-bezier(0.34, 0.01, 0.23, 0.98); }

.lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(48, 48, 48, 0.9);
  z-index: 1100;
  transition: 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98);
  width: 100%; }
  .lightbox .lightbox-itemwrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    transition: 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    .lightbox .lightbox-itemwrapper img {
      margin: auto;
      max-height: 90vh;
      width: 100vw;
      max-width: 100%;
      padding: 20px;
      object-fit: scale-down;
      cursor: zoom-out; }
    .lightbox .lightbox-itemwrapper p {
      margin: auto;
      text-align: center;
      max-width: 800px;
      color: #e9e9e9; }
  .lightbox.out {
    opacity: 0; }
    .lightbox.out .lightbox-itemwrapper {
      transform: translateY(300px); }
  .lightbox.nocaption .lightbox-itemwrapper img {
    max-height: 100vh; }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.no-jss .nojspage {
  z-index: 10000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: fade 0.1s 0.5s;
  animation-fill-mode: both; }

.errorpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e9e9e9;
  text-align: center; }
  .errorpage p {
    width: 100%; }
  .errorpage .bigger {
    font-size: 130%;
    margin-bottom: 20px; }

.nojspage {
  display: none; }

.no-msie .msie.warning {
  display: none !important; }

body {
  overflow-x: hidden; }

@keyframes pulselogo {
  0%, 25%, 50% {
    transform: translateY(0); }
  12%, 37% {
    transform: translateY(10px); } }

@keyframes subtitleup {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-70px); } }

@keyframes subtitleup_small {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-50px); } }

@keyframes scrollanim {
  0%, 80% {
    transform: translateY(0); }
  30% {
    transform: translateY(-10px); }
  50% {
    transform: translateY(10px); } }

.herowrapper {
  height: 90vh;
  margin: auto;
  max-width: 75rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.herobox {
  min-width: 100%;
  margin: auto; }
  .herobox .herologo {
    position: relative;
    max-width: 800px;
    margin: 100px auto 0; }
    .herobox .herologo #tfwsplash, .herobox .herologo #tfwsplash-back {
      backface-visibility: hidden;
      transition: all 0.6s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.3s; }
    .herobox .herologo #tfwsplash-back {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transform: rotate3d(0, 1, 0, 180deg); }
    .herobox .herologo:not(.fliplocked):hover #tfwsplash {
      margin: 70px 0;
      transform: rotate3d(0, 1, 0, 180deg); }
    .herobox .herologo:not(.fliplocked):hover #tfwsplash-back {
      margin: 70px 0;
      transform: rotate3d(0, 0, 0, 0deg); }
  .herobox .subtitle {
    width: auto;
    text-align: center;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-size: 200%;
    letter-spacing: 15px;
    color: #174b70;
    text-indent: 8px;
    animation: subtitleup 0.8s cubic-bezier(0.34, 0.01, 0.23, 0.98) 2.8s;
    animation-fill-mode: both; }
  .herobox #intro {
    max-width: 800px;
    margin: 120px auto 20px; }
    .herobox #intro .cbutton, .herobox #intro #contactme form .textfield .contact-placeholder, #contactme form .textfield .herobox #intro .contact-placeholder {
      width: 100%;
      overflow-x: hidden; }
      .herobox #intro .cbutton:hover, .herobox #intro #contactme form .textfield .contact-placeholder:hover, #contactme form .textfield .herobox #intro .contact-placeholder:hover {
        letter-spacing: 15px; }
    .herobox #intro #mywork {
      background-color: transparent;
      color: #0b375b;
      border: 1px solid #0b375b; }

#scrollanimation {
  position: static;
  display: block;
  margin: 50px auto;
  animation: scrollanim 2s cubic-bezier(0.34, 0.01, 0.23, 0.98) infinite;
  stroke: #466c90;
  stroke-width: 3;
  stroke-linecap: round;
  opacity: 0.5; }

.content-wrapper {
  margin: -100px 3vw 0;
  overflow-x: visible;
  min-width: 0; }
  .content-wrapper.extended {
    transition: all 0.3s cubic-bezier(0.34, 0.01, 0.23, 0.98);
    margin: -100px 0 0;
    min-width: 100%; }

@media print, screen and (min-width: 40em) {
  #aboutme {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 100px auto;
    height: 500px;
    max-width: 75rem;
    flex-direction: row; } }
  @media screen and (min-width: 40em) and (min-width: 40em) and (max-width: 63.9375em) {
    #aboutme {
      height: 450px; } }

@media print, screen and (min-width: 40em) {
    #aboutme .p-wrapper > * {
      transition: opacity 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #aboutme.hover .section-image {
      margin-left: -40px; }
      #aboutme.hover .section-image .header.white {
        margin-left: 40px; }
    #aboutme.hover .p-wrapper > * {
      opacity: 0.5; }
    #aboutme .section-text {
      text-align: right;
      width: 40%;
      margin: auto 0;
      padding-bottom: 50px; }
      #aboutme .section-text .dummy {
        opacity: 0; }
      #aboutme .section-text .p-wrapper {
        margin: 0 20px 40px 0; }
    #aboutme .readmore.cbutton, #aboutme #contactme form .textfield .readmore.contact-placeholder, #contactme form .textfield #aboutme .readmore.contact-placeholder {
      line-height: 38px;
      height: 40px;
      margin: 0 -40px 0 0;
      padding: 0 54px 0 20px; }
    #aboutme .readmore.white.cbutton, #aboutme #contactme form .textfield .readmore.white.contact-placeholder, #contactme form .textfield #aboutme .readmore.white.contact-placeholder {
      position: absolute;
      width: 40px;
      display: inline-flex;
      left: 0;
      right: auto;
      white-space: nowrap;
      box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
      padding: 5px;
      margin: 0;
      color: #0b375b;
      background-color: #e9e9e9; }
      #aboutme .readmore.white.cbutton img, #aboutme #contactme form .textfield .readmore.white.contact-placeholder img, #contactme form .textfield #aboutme .readmore.white.contact-placeholder img {
        margin: auto;
        height: 30px;
        transform: rotate(0deg); }
    #aboutme .header {
      position: absolute;
      text-overflow: ellipsis;
      white-space: nowrap; }
      #aboutme .header.blue {
        text-align: left;
        left: 20%;
        right: 0; }
      #aboutme .header.white {
        z-index: 1;
        color: #e9e9e9;
        text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
        transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #aboutme .section-image {
      float: left;
      z-index: 0;
      pointer-events: none;
      padding: 0 0 0 40%;
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0; }
      #aboutme .section-image .img, #aboutme .section-image #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider #aboutme .section-image .pf-item, #aboutme .section-image #portfolio-small .pf-item, #portfolio-small #aboutme .section-image .pf-item {
        pointer-events: none;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-color: #616161;
        width: auto;
        height: 100%;
        transition: transform 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
      #aboutme .section-image .img *, #aboutme .section-image #portfolio .orbit-slider .pf-item *, #portfolio .orbit-slider #aboutme .section-image .pf-item *, #aboutme .section-image #portfolio-small .pf-item *, #portfolio-small #aboutme .section-image .pf-item * {
        pointer-events: auto; }
      #aboutme .section-image .frame {
        transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #aboutme.fallback .section-image {
      transition: none;
      left: 40%;
      right: 0;
      padding: 0;
      width: auto; }
      #aboutme.fallback .section-image.hover {
        padding: 0; }
        #aboutme.fallback .section-image.hover .img, #aboutme.fallback .section-image.hover #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider #aboutme.fallback .section-image.hover .pf-item, #aboutme.fallback .section-image.hover #portfolio-small .pf-item, #portfolio-small #aboutme.fallback .section-image.hover .pf-item {
          transform: scale(1.02); }
    #aboutme.element-out .header {
      transform: translateX(-100px);
      opacity: 0; }
    #aboutme.element-out .readmore,
    #aboutme.element-out .p-wrapper {
      transform: translateY(70px);
      opacity: 0; }
    #aboutme.element-in .header,
    #aboutme.element-in .readmore,
    #aboutme.element-in .section-image,
    #aboutme.element-in .p-wrapper {
      transform: translateX(0px);
      opacity: 1; }
    #aboutme.element-in .section-image {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98), padding 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98), margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #aboutme.element-in .p-wrapper {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.7s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.7s, color 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0s; }
    #aboutme.element-in .readmore {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.85s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.85s, left 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0s; }
    #aboutme.element-in .header {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.55s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.55s, margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #aboutme.element-in .vanish {
      transition: opacity 0.15s cubic-bezier(0.34, 0.01, 0.23, 0.98);
      opacity: 0; }
    #aboutme.extended {
      transition: max-width 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98), height 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
      height: 90vh;
      min-width: 100%; }
      #aboutme.extended .section-image {
        position: absolute;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0; }
        #aboutme.extended .section-image .img, #aboutme.extended .section-image #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider #aboutme.extended .section-image .pf-item, #aboutme.extended .section-image #portfolio-small .pf-item, #portfolio-small #aboutme.extended .section-image .pf-item {
          transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
          margin: 0; }
  #skills {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 100px auto;
    height: 600px;
    max-width: 75rem;
    flex-direction: row-reverse; } }
  @media screen and (min-width: 40em) and (min-width: 40em) and (max-width: 63.9375em) {
    #skills {
      height: 540px; } }

@media print, screen and (min-width: 40em) {
    #skills .p-wrapper > * {
      transition: opacity 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #skills.hover .section-image {
      margin-left: 40px; }
      #skills.hover .section-image .header.white {
        margin-left: -40px; }
    #skills.hover .p-wrapper > * {
      opacity: 0.5; }
    #skills .section-text {
      text-align: left;
      width: 50%;
      margin: auto 0;
      padding-bottom: 50px; }
      #skills .section-text .dummy {
        opacity: 0; }
      #skills .section-text .p-wrapper {
        margin: 0 0 40px 20px; }
    #skills .readmore.cbutton, #skills #contactme form .textfield .readmore.contact-placeholder, #contactme form .textfield #skills .readmore.contact-placeholder {
      line-height: 38px;
      height: 40px;
      margin: 0 0 0 -40px;
      padding: 0 20px 0 56px; }
    #skills .readmore.white.cbutton, #skills #contactme form .textfield .readmore.white.contact-placeholder, #contactme form .textfield #skills .readmore.white.contact-placeholder {
      position: absolute;
      width: 40px;
      display: inline-flex;
      left: auto;
      right: 0;
      white-space: nowrap;
      box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.25);
      padding: 5px;
      margin: 0;
      color: #0b375b;
      background-color: #e9e9e9; }
      #skills .readmore.white.cbutton img, #skills #contactme form .textfield .readmore.white.contact-placeholder img, #contactme form .textfield #skills .readmore.white.contact-placeholder img {
        margin: auto;
        height: 30px;
        transform: rotate(-180deg); }
    #skills .header {
      position: absolute;
      text-overflow: ellipsis;
      white-space: nowrap; }
      #skills .header.blue {
        text-align: right;
        left: auto;
        right: 15%; }
      #skills .header.white {
        z-index: 1;
        color: #e9e9e9;
        text-shadow: -2px 3px 4px rgba(0, 0, 0, 0.25);
        transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #skills .section-image {
      float: right;
      z-index: 0;
      pointer-events: none;
      padding: 0 50% 0 0;
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0; }
      #skills .section-image .img, #skills .section-image #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider #skills .section-image .pf-item, #skills .section-image #portfolio-small .pf-item, #portfolio-small #skills .section-image .pf-item {
        pointer-events: none;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-color: #616161;
        width: auto;
        height: 100%;
        transition: transform 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
      #skills .section-image .img *, #skills .section-image #portfolio .orbit-slider .pf-item *, #portfolio .orbit-slider #skills .section-image .pf-item *, #skills .section-image #portfolio-small .pf-item *, #portfolio-small #skills .section-image .pf-item * {
        pointer-events: auto; }
      #skills .section-image .frame {
        transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #skills.fallback .section-image {
      transition: none;
      left: 0;
      right: 50%;
      padding: 0;
      width: auto; }
      #skills.fallback .section-image.hover {
        padding: 0; }
        #skills.fallback .section-image.hover .img, #skills.fallback .section-image.hover #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider #skills.fallback .section-image.hover .pf-item, #skills.fallback .section-image.hover #portfolio-small .pf-item, #portfolio-small #skills.fallback .section-image.hover .pf-item {
          transform: scale(1.02); }
    #skills.element-out .header {
      transform: translateX(100px);
      opacity: 0; }
    #skills.element-out .readmore,
    #skills.element-out .p-wrapper {
      transform: translateY(70px);
      opacity: 0; }
    #skills.element-in .header,
    #skills.element-in .readmore,
    #skills.element-in .section-image,
    #skills.element-in .p-wrapper {
      transform: translateX(0px);
      opacity: 1; }
    #skills.element-in .section-image {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98), padding 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98), margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #skills.element-in .p-wrapper {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.7s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.7s, color 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0s; }
    #skills.element-in .readmore {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.85s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.85s, right 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0s; }
    #skills.element-in .header {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.55s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.55s, margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #skills.element-in .vanish {
      transition: opacity 0.15s cubic-bezier(0.34, 0.01, 0.23, 0.98);
      opacity: 0; }
    #skills.extended {
      transition: max-width 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98), height 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
      height: 90vh;
      min-width: 100%; }
      #skills.extended .section-image {
        position: absolute;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0; }
        #skills.extended .section-image .img, #skills.extended .section-image #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider #skills.extended .section-image .pf-item, #skills.extended .section-image #portfolio-small .pf-item, #portfolio-small #skills.extended .section-image .pf-item {
          transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
          margin: 0; }
  #portfolio {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 100px auto;
    height: 450px;
    max-width: 75rem;
    flex-direction: row; } }
  @media screen and (min-width: 40em) and (min-width: 40em) and (max-width: 63.9375em) {
    #portfolio {
      height: 405px; } }

@media print, screen and (min-width: 40em) {
    #portfolio .p-wrapper > * {
      transition: opacity 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #portfolio.hover .section-image {
      margin-left: -40px; }
      #portfolio.hover .section-image .header.white {
        margin-left: 40px; }
    #portfolio.hover .p-wrapper > * {
      opacity: 0.5; }
    #portfolio .section-text {
      text-align: right;
      width: 40%;
      margin: auto 0;
      padding-bottom: 50px; }
      #portfolio .section-text .dummy {
        opacity: 0; }
      #portfolio .section-text .p-wrapper {
        margin: 0 20px 40px 0; }
    #portfolio .readmore.cbutton, #portfolio #contactme form .textfield .readmore.contact-placeholder, #contactme form .textfield #portfolio .readmore.contact-placeholder {
      line-height: 38px;
      height: 40px;
      margin: 0 -40px 0 0;
      padding: 0 54px 0 20px; }
    #portfolio .readmore.white.cbutton, #portfolio #contactme form .textfield .readmore.white.contact-placeholder, #contactme form .textfield #portfolio .readmore.white.contact-placeholder {
      position: absolute;
      width: 40px;
      display: inline-flex;
      left: 0;
      right: auto;
      white-space: nowrap;
      box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
      padding: 5px;
      margin: 0;
      color: #0b375b;
      background-color: #e9e9e9; }
      #portfolio .readmore.white.cbutton img, #portfolio #contactme form .textfield .readmore.white.contact-placeholder img, #contactme form .textfield #portfolio .readmore.white.contact-placeholder img {
        margin: auto;
        height: 30px;
        transform: rotate(0deg); }
    #portfolio .header {
      position: absolute;
      text-overflow: ellipsis;
      white-space: nowrap; }
      #portfolio .header.blue {
        text-align: left;
        left: 20%;
        right: 0; }
      #portfolio .header.white {
        z-index: 1;
        color: #e9e9e9;
        text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
        transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #portfolio .section-image {
      float: left;
      z-index: 0;
      pointer-events: none;
      padding: 0 0 0 40%;
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0; }
      #portfolio .section-image .img, #portfolio .section-image .orbit-slider .pf-item, #portfolio .orbit-slider .section-image .pf-item, #portfolio .section-image #portfolio-small .pf-item, #portfolio-small #portfolio .section-image .pf-item {
        pointer-events: none;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-color: #616161;
        width: auto;
        height: 100%;
        transition: transform 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
      #portfolio .section-image .img *, #portfolio .section-image .orbit-slider .pf-item *, #portfolio .orbit-slider .section-image .pf-item *, #portfolio .section-image #portfolio-small .pf-item *, #portfolio-small #portfolio .section-image .pf-item * {
        pointer-events: auto; }
      #portfolio .section-image .frame {
        transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #portfolio.fallback .section-image {
      transition: none;
      left: 40%;
      right: 0;
      padding: 0;
      width: auto; }
      #portfolio.fallback .section-image.hover {
        padding: 0; }
        #portfolio.fallback .section-image.hover .img, #portfolio.fallback .section-image.hover .orbit-slider .pf-item, #portfolio.fallback .orbit-slider .section-image.hover .pf-item, #portfolio.fallback .section-image.hover #portfolio-small .pf-item, #portfolio-small #portfolio.fallback .section-image.hover .pf-item {
          transform: scale(1.02); }
    #portfolio.element-out .header {
      transform: translateX(-100px);
      opacity: 0; }
    #portfolio.element-out .readmore,
    #portfolio.element-out .p-wrapper {
      transform: translateY(70px);
      opacity: 0; }
    #portfolio.element-in .header,
    #portfolio.element-in .readmore,
    #portfolio.element-in .section-image,
    #portfolio.element-in .p-wrapper {
      transform: translateX(0px);
      opacity: 1; }
    #portfolio.element-in .section-image {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98), padding 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98), margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #portfolio.element-in .p-wrapper {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.7s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.7s, color 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0s; }
    #portfolio.element-in .readmore {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.85s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.85s, left 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0s; }
    #portfolio.element-in .header {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.55s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.55s, margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #portfolio.element-in .vanish {
      transition: opacity 0.15s cubic-bezier(0.34, 0.01, 0.23, 0.98);
      opacity: 0; }
    #portfolio.extended {
      transition: max-width 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98), height 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
      height: 90vh;
      min-width: 100%; }
      #portfolio.extended .section-image {
        position: absolute;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0; }
        #portfolio.extended .section-image .img, #portfolio.extended .section-image .orbit-slider .pf-item, #portfolio.extended .orbit-slider .section-image .pf-item, #portfolio.extended .section-image #portfolio-small .pf-item, #portfolio-small #portfolio.extended .section-image .pf-item {
          transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
          margin: 0; }
  #contactme {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 100px auto;
    height: 400px;
    max-width: 75rem;
    flex-direction: row-reverse; } }
  @media screen and (min-width: 40em) and (min-width: 40em) and (max-width: 63.9375em) {
    #contactme {
      height: 360px; } }

@media print, screen and (min-width: 40em) {
    #contactme .p-wrapper > * {
      transition: opacity 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #contactme.hover .section-image {
      margin-left: 40px; }
      #contactme.hover .section-image .header.white {
        margin-left: -40px; }
    #contactme.hover .p-wrapper > * {
      opacity: 0.5; }
    #contactme .section-text {
      text-align: left;
      width: 40%;
      margin: auto 0;
      padding-bottom: 50px; }
      #contactme .section-text .dummy {
        opacity: 0; }
      #contactme .section-text .p-wrapper {
        margin: 0 0 40px 20px; }
    #contactme .readmore.cbutton, #contactme form .textfield .readmore.contact-placeholder {
      line-height: 38px;
      height: 40px;
      margin: 0 0 0 -40px;
      padding: 0 20px 0 56px; }
    #contactme .readmore.white.cbutton, #contactme form .textfield .readmore.white.contact-placeholder {
      position: absolute;
      width: 40px;
      display: inline-flex;
      left: auto;
      right: 0;
      white-space: nowrap;
      box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.25);
      padding: 5px;
      margin: 0;
      color: #0b375b;
      background-color: #e9e9e9; }
      #contactme .readmore.white.cbutton img, #contactme form .textfield .readmore.white.contact-placeholder img {
        margin: auto;
        height: 30px;
        transform: rotate(-180deg); }
    #contactme .header {
      position: absolute;
      text-overflow: ellipsis;
      white-space: nowrap; }
      #contactme .header.blue {
        text-align: right;
        left: auto;
        right: 10%; }
      #contactme .header.white {
        z-index: 1;
        color: #e9e9e9;
        text-shadow: -2px 3px 4px rgba(0, 0, 0, 0.25);
        transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #contactme .section-image {
      float: right;
      z-index: 0;
      pointer-events: none;
      padding: 0 40% 0 0;
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0; }
      #contactme .section-image .img, #contactme .section-image #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider #contactme .section-image .pf-item, #contactme .section-image #portfolio-small .pf-item, #portfolio-small #contactme .section-image .pf-item {
        pointer-events: none;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-color: #616161;
        width: auto;
        height: 100%;
        transition: transform 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
      #contactme .section-image .img *, #contactme .section-image #portfolio .orbit-slider .pf-item *, #portfolio .orbit-slider #contactme .section-image .pf-item *, #contactme .section-image #portfolio-small .pf-item *, #portfolio-small #contactme .section-image .pf-item * {
        pointer-events: auto; }
      #contactme .section-image .frame {
        transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #contactme.fallback .section-image {
      transition: none;
      left: 0;
      right: 40%;
      padding: 0;
      width: auto; }
      #contactme.fallback .section-image.hover {
        padding: 0; }
        #contactme.fallback .section-image.hover .img, #contactme.fallback .section-image.hover #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider #contactme.fallback .section-image.hover .pf-item, #contactme.fallback .section-image.hover #portfolio-small .pf-item, #portfolio-small #contactme.fallback .section-image.hover .pf-item {
          transform: scale(1.02); }
    #contactme.element-out .header {
      transform: translateX(100px);
      opacity: 0; }
    #contactme.element-out .readmore,
    #contactme.element-out .p-wrapper {
      transform: translateY(70px);
      opacity: 0; }
    #contactme.element-in .header,
    #contactme.element-in .readmore,
    #contactme.element-in .section-image,
    #contactme.element-in .p-wrapper {
      transform: translateX(0px);
      opacity: 1; }
    #contactme.element-in .section-image {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98), padding 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98), margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #contactme.element-in .p-wrapper {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.7s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.7s, color 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0s; }
    #contactme.element-in .readmore {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.85s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.85s, right 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0s; }
    #contactme.element-in .header {
      transition: transform 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.55s, opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.55s, margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #contactme.element-in .vanish {
      transition: opacity 0.15s cubic-bezier(0.34, 0.01, 0.23, 0.98);
      opacity: 0; }
    #contactme.extended {
      transition: max-width 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98), height 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
      height: 90vh;
      min-width: 100%; }
      #contactme.extended .section-image {
        position: absolute;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0; }
        #contactme.extended .section-image .img, #contactme.extended .section-image #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider #contactme.extended .section-image .pf-item, #contactme.extended .section-image #portfolio-small .pf-item, #portfolio-small #contactme.extended .section-image .pf-item {
          transition: margin 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
          margin: 0; } }

.vanish {
  transition: opacity 0.2s cubic-bezier(0.34, 0.01, 0.23, 0.98);
  opacity: 0; }

@media print, screen and (min-width: 64em) {
  background-color: #4a4a4a; }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  background-color: green; }

@media screen and (max-width: 39.9375em) {
  background-color: blue; }

@media screen and (max-width: 39.9375em) {
  .herowrapper {
    height: 60vh; }
    .herowrapper .herobox {
      position: static;
      pointer-events: none; }
    .herowrapper .herobox .herologo #tfwsplash {
      display: block;
      max-width: 95vw;
      margin: auto; }
    .herowrapper .herobox .herologo #tfwsplash-back {
      transform: rotate3d(0, 0, 0, 0deg) translateY(-25vw);
      opacity: 0.05;
      height: 110vw;
      object-fit: cover; }
    .herowrapper .subtitle {
      margin-top: 10px;
      font-size: 4.5vw;
      letter-spacing: 2vw;
      animation-name: subtitleup_small; }
    .herowrapper #mainbuttons {
      margin-top: 50px;
      max-width: 400px; }
  #scrollanimation {
    width: 40px;
    margin: -10px auto 10px; }
  .content-wrapper {
    margin: 0; }
  .section-wrapper {
    margin-top: 50px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column-reverse; }
    .section-wrapper .dummy,
    .section-wrapper .header.blue,
    .section-wrapper .readmore img,
    .section-wrapper .readmore.white {
      display: none; }
    .section-wrapper .section-image {
      width: 100%;
      height: 250px;
      position: relative; }
      .section-wrapper .section-image .img, .section-wrapper .section-image #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider .section-wrapper .section-image .pf-item, .section-wrapper .section-image #portfolio-small .pf-item, #portfolio-small .section-wrapper .section-image .pf-item {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover; }
      .section-wrapper .section-image .ishadow {
        box-shadow: 0 -60px 50px -20px #303030 inset; }
    .section-wrapper .header.white {
      position: absolute;
      z-index: 1;
      color: #e9e9e9;
      text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
      line-height: 90%;
      bottom: 10px;
      text-align: center;
      width: 100%; }
    .section-wrapper p {
      margin: 20px 15px 50px;
      text-align: center; }
    .section-wrapper .centerme {
      display: block;
      text-align: center; }
      .section-wrapper .centerme .readmore.blue {
        max-width: 300px; }
    .section-wrapper.element-out:not(#aboutme) {
      transform: translateY(100px);
      opacity: 0; }
    .section-wrapper.element-in {
      transform: translateY(0);
      opacity: 1;
      transition: 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.5s; }
  #skills p {
    margin-bottom: 10px; } }

.masonry {
  position: relative;
  margin: 10px -10px; }
  .masonry img {
    padding: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center; }
  .masonry .gallery-item {
    cursor: zoom-in; }
  .masonry .videowrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0; }
  .masonry .videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 8px; }
  @media screen and (max-width: 39.9375em) {
    .masonry img, .masonry .videowrapper {
      object-fit: contain;
      max-height: 300px; } }

@keyframes heroshadow {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.mainpages .hero-image,
.portfoliopages .hero-image {
  position: fixed;
  z-index: -1; }

.mainpages .sideicon-wrapper,
.portfoliopages .sideicon-wrapper {
  pointer-events: none;
  position: absolute;
  top: 90vh;
  margin-top: 100px;
  height: 100vh;
  width: 33%; }
  .mainpages .sideicon-wrapper .sideicon,
  .portfoliopages .sideicon-wrapper .sideicon {
    position: sticky;
    top: 80px;
    left: 0;
    width: 100%;
    padding: 25px 50px;
    transition: opacity 0.7s cubic-bezier(0.34, 0.01, 0.23, 0.98);
    opacity: 0.07; }
    .mainpages .sideicon-wrapper .sideicon.element-out,
    .portfoliopages .sideicon-wrapper .sideicon.element-out {
      opacity: 0; }

.mainpages .content-wrapper,
.portfoliopages .content-wrapper {
  margin: auto;
  padding: 20px;
  padding-top: 50px;
  max-width: 75rem;
  height: auto; }
  .mainpages .content-wrapper .bodytext,
  .portfoliopages .content-wrapper .bodytext {
    line-height: 180%;
    max-width: 700px; }

.mainpages .hero,
.portfoliopages .hero {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden; }
  .mainpages .hero .boxshadow,
  .portfoliopages .hero .boxshadow {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    animation: heroshadow 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
    background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.7) 100%); }
  .mainpages .hero .hero-image,
  .portfoliopages .hero .hero-image {
    background-color: #616161;
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center; }
  .mainpages .hero .hero-details,
  .portfoliopages .hero .hero-details {
    position: absolute;
    bottom: 120px;
    margin-bottom: -2rem;
    width: 100%;
    text-align: center;
    color: #e9e9e9;
    font: italic normal normal 2rem "Open Sans", sans-serif; }
  .mainpages .hero .header.white,
  .portfoliopages .hero .header.white {
    color: #e9e9e9;
    text-shadow: 5px 8px 10px rgba(0, 0, 0, 0.25); }
  .mainpages .hero .header.blue,
  .portfoliopages .hero .header.blue {
    color: #174b70; }

.mainpages .header {
  position: absolute;
  text-align: center;
  direction: rtl;
  width: 100%;
  white-space: nowrap;
  top: calc(90vh - 380px);
  width: 100%;
  max-width: 1200px;
  margin-left: 0;
  padding: 25px;
  font-size: 10rem;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.34, 0.01, 0.23, 0.98);
  transition-property: font-size, transform, opacity, max-width; }
  @media screen and (max-width: 63.9375em) {
    .mainpages .header {
      transform: translateY(90px);
      font-size: 6rem; } }
  .mainpages .header.toside {
    height: auto;
    font-size: 4rem;
    transform: translateY(380px);
    max-width: 33%; }

.mainpages .header.blue {
  z-index: -5; }

.mainpages .fixed {
  position: fixed;
  bottom: auto;
  top: -400px; }

.mainpages .pf-overview {
  max-width: 700px; }

.mainpages h3 {
  color: #cacaca;
  margin-top: 30px; }

.mainpages .skillpies h5 {
  text-align: center;
  margin-bottom: 12px; }

@media only screen and (min-width: 1200px) {
  .mainpages .header {
    margin-left: calc((100% - 1200px) / 2); }
    .mainpages .header.toside {
      max-width: 400px; }
  .mainpages .sideicon-wrapper {
    left: calc((100% - 1200px) / 2);
    width: 400px; } }

@media screen and (max-width: 39.9375em) {
  .mainpages,
  .portfoliopages {
    margin-top: 40px; }
    .mainpages .content-wrapper,
    .portfoliopages .content-wrapper {
      padding-top: 20px; }
  .mainpages .boxshadow {
    background: linear-gradient(to bottom, transparent 30%, rgba(0, 0, 0, 0.7) 100%); }
  .mainpages .hero, .mainpages .hero .hero-image {
    height: 50vh; }
    .mainpages .hero .header-small, .mainpages .hero .hero-image .header-small {
      position: absolute;
      text-align: center;
      width: 100%;
      color: #e9e9e9;
      text-shadow: 5px 8px 10px rgba(0, 0, 0, 0.25);
      bottom: 30px; }
    .mainpages .hero .hero-details, .mainpages .hero .hero-image .hero-details {
      bottom: 50px;
      font-size: 1.2rem; }
  .mainpages .pf-overview {
    width: 100%; }
  .mainpages .skillpies h5 {
    font-size: 60%; } }

.navigation {
  z-index: 1000;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0; }

@keyframes wave {
  0% {
    padding-right: 15px; }
  100% {
    padding-right: 25px; } }
  .navigation #burger {
    pointer-events: all;
    position: fixed;
    top: 0;
    right: 0;
    width: 100px;
    height: 100px;
    z-index: 999;
    background: radial-gradient(at 70px 30px, rgba(233, 233, 233, 0.7) 0%, rgba(233, 233, 233, 0) 60%); }
    .navigation #burger svg {
      float: right;
      margin-top: 15px;
      margin-right: 15px; }
    .navigation #burger svg line {
      stroke-width: 3;
      stroke: #0b375b;
      transform: translateX(40px);
      transition: transform 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.2s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    .navigation #burger .line-1 {
      transition-delay: 0.28s; }
    .navigation #burger .line-2 {
      transition-delay: 0.36s; }
    .navigation #burger .line-3 {
      transition-delay: 0.44s; }
  .navigation #nav-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    opacity: 0.3;
    transition: opacity 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
  .navigation #list {
    position: absolute;
    top: 15px;
    right: 15px; }
  .navigation #nav-background {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 300px;
    overflow: hidden;
    background-color: #e9e9e9;
    box-shadow: 5px 0 20px rgba(11, 55, 91, 0.4);
    transition: 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
  .navigation .logo {
    height: 20vh;
    width: 300px;
    object-fit: contain;
    padding-top: 20px;
    padding-left: 10px;
    transition: 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.8s; }
  .navigation #linkedin {
    height: 50px;
    position: absolute;
    bottom: 5vh;
    right: 25px;
    transition: opacity 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.8s, fill 0.2s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0s;
    fill: rgba(97, 97, 97, 0.5); }
    .navigation #linkedin:hover {
      fill: #466c90; }
  .navigation #list {
    right: 0;
    margin-top: 20vh;
    display: flex;
    width: 300px;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden; }
    .navigation #list .navitem-1 {
      transition: padding-right 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.28s, background-color 0.3s cubic-bezier(0.34, 0.01, 0.23, 0.98), transform 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.28s; }
    .navigation #list .navitem-2 {
      transition: padding-right 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.36s, background-color 0.3s cubic-bezier(0.34, 0.01, 0.23, 0.98), transform 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.36s; }
    .navigation #list .navitem-3 {
      transition: padding-right 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.44s, background-color 0.3s cubic-bezier(0.34, 0.01, 0.23, 0.98), transform 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.44s; }
    .navigation #list .navitem-4 {
      transition: padding-right 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.52s, background-color 0.3s cubic-bezier(0.34, 0.01, 0.23, 0.98), transform 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.52s; }
    .navigation #list .navitem-5 {
      transition: padding-right 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.6s, background-color 0.3s cubic-bezier(0.34, 0.01, 0.23, 0.98), transform 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98), opacity 0.4s cubic-bezier(0.34, 0.01, 0.23, 0.98) 0.6s; }
    .navigation #list li {
      text-align: right;
      font-family: "Roboto", sans-serif;
      text-transform: uppercase;
      list-style-type: none;
      font-size: 1rem;
      letter-spacing: 8px;
      text-indent: 2px;
      padding: 0.8em 1em;
      word-spacing: -10px;
      width: 350px;
      transform: translate(0, 0);
      padding: 3vh 65px 3vh 0; }
      .navigation #list li:hover {
        transform: translateX(-5px);
        transition-delay: 0;
        background-color: #e0e0e0; }
      .navigation #list li.thispage {
        background-color: #466c90; }
        .navigation #list li.thispage a {
          color: #e9e9e9; }
  .navigation.unactive {
    pointer-events: none; }
    .navigation.unactive #burger line {
      transform: translate(0); }
    .navigation.unactive .logo, .navigation.unactive #linkedin {
      opacity: 0;
      transition: opacity 0.01s 0.5s; }
    .navigation.unactive #nav-background {
      transition-delay: 0s;
      border-width: 0; }
    .navigation.unactive #list li {
      padding-right: 0;
      transition-delay: 0.2s;
      opacity: 0; }
    .navigation.unactive #nav-overlay {
      opacity: 0; }

@media print, screen and (min-width: 40em) {
  .navigation.unactive #nav-background {
    transform: translateX(300px); } }

@media screen and (max-width: 39.9375em) {
  .navigation #burger {
    background: transparent;
    transform: translateY(calc(85vh - 40px));
    transition: transform 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    .navigation #burger svg {
      height: 30px;
      margin-right: 5px;
      margin-top: 5px; }
      .navigation #burger svg line {
        transition-duration: 0.2s;
        transition-delay: 0.3s !important; }
      .navigation #burger svg .line-1 {
        transform-origin: 50% 8px;
        transform: translateY(12px) rotate(-45deg); }
      .navigation #burger svg .line-2 {
        opacity: 0; }
      .navigation #burger svg .line-3 {
        transform-origin: 50% 32px;
        transform: translateY(-12px) rotate(45deg); }
  .navigation #nav-background {
    top: 0;
    width: 100%;
    height: 85vh; }
    .navigation #nav-background .logo {
      height: 100px;
      width: 100%;
      padding-left: 5px;
      transition-delay: 0s; }
    .navigation #nav-background #linkedin {
      height: 30px;
      right: auto;
      left: 5px;
      bottom: 5px; }
    .navigation #nav-background #list {
      width: 100%;
      margin: 100px 0 40px; }
    .navigation #nav-background #list li {
      padding: 2.3vh 0;
      text-align: center;
      width: 100%;
      text-indent: 5px; }
      .navigation #nav-background #list li:hover {
        transform: translateX(0); }
  .navigation.unactive #burger {
    transform: translateY(0); }
    .navigation.unactive #burger svg line {
      transform: translate(0, 0) rotate(0deg);
      opacity: 1; }
  .navigation.unactive #nav-background {
    height: 40px; } }

#contactme {
  pointer-events: auto; }
  #contactme .readmore.white img {
    transform: rotate(0) !important; }
  #contactme .section-image .img, #contactme .section-image #portfolio .orbit-slider .pf-item, #portfolio .orbit-slider #contactme .section-image .pf-item, #contactme .section-image #portfolio-small .pf-item, #portfolio-small #contactme .section-image .pf-item {
    background-color: #e9e9e9 !important;
    height: auto;
    overflow-y: hidden; }
  #contactme .header.white {
    pointer-events: none; }
  #contactme #error-message {
    padding-left: 15px;
    color: #d54e54; }
    #contactme #error-message.positive {
      color: #409042; }
  #contactme form {
    margin: 0 auto; }
    #contactme form .textfield {
      padding-left: 15px;
      position: relative; }
      #contactme form .textfield .input-wrapper {
        position: relative;
        overflow: hidden; }
      #contactme form .textfield .contact-placeholder {
        pointer-events: none;
        position: absolute;
        top: 0;
        opacity: 1;
        font-family: "Roboto", sans-serif;
        text-transform: uppercase;
        background-color: transparent;
        padding: 0 20px;
        color: rgba(233, 233, 233, 0.8);
        line-height: 50px;
        transition: top 0.2s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
      #contactme form .textfield input,
      #contactme form .textfield textarea {
        color: #e9e9e9;
        text-indent: 10px;
        border: none;
        background-color: #0b375b;
        transition: background-color 0.3s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
        #contactme form .textfield input:focus, #contactme form .textfield input:hover,
        #contactme form .textfield textarea:focus,
        #contactme form .textfield textarea:hover {
          background-color: #174b70; }
        #contactme form .textfield input.error,
        #contactme form .textfield textarea.error {
          border-left: 10px solid #d54e54;
          text-indent: 0; }
      #contactme form .textfield input {
        height: 50px; }
      #contactme form .textfield textarea {
        min-height: 200px;
        min-width: 100%; }
      #contactme form .textfield.active .contact-placeholder {
        top: -40px; }
    #contactme form .submitbutton input {
      width: 100%;
      height: 50px;
      padding: 0; }
    #contactme form.sent input, #contactme form.sent textarea {
      background-color: #cacaca !important;
      pointer-events: none !important; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    #contactme {
      height: 400px; } }
  @media screen and (max-width: 39.9375em) {
    #contactme {
      flex-direction: column; }
      #contactme .section-text p {
        margin: 0 15px 15px; }
      #contactme .header.blue {
        display: block;
        text-align: center; }
      #contactme .section-image {
        top: -80px;
        height: auto; }
      #contactme .centerme {
        position: relative;
        top: 480px;
        z-index: 1; }
      #contactme #error-message {
        margin-top: -5px; }
      #contactme form .textfield {
        padding: 0 15px; }
        #contactme form .textfield .contact-placeholder,
        #contactme form .textfield input,
        #contactme form .textfield textarea {
          font-size: 80%; } }

footer {
  margin: 80px 5vw 40px 5vw; }
  footer #footer-wrapper {
    border-top: 1px solid #cacaca; }
  footer .callout {
    background-color: #466c90;
    color: #e9e9e9;
    display: flex; }
    footer .callout img {
      width: 60px;
      height: auto; }
    footer .callout p {
      color: #e9e9e9; }
    footer .callout a {
      color: #e9e9e9;
      text-decoration: underline; }
      footer .callout a:hover {
        opacity: 0.5; }
  footer p {
    text-align: center;
    color: #8a8a8a;
    font-size: 95%; }
  footer #footerlogo {
    display: block;
    margin: 0px auto 0px;
    width: 250px;
    height: auto;
    filter: grayscale(1);
    opacity: 0.1; }
  footer #socialwrapper {
    display: flex;
    justify-content: center;
    margin: 15px; }
    footer #socialwrapper #linkedin {
      width: 40px;
      fill: rgba(97, 97, 97, 0.2);
      transition: 0.2s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
      footer #socialwrapper #linkedin:hover {
        fill: #466c90; }
  footer #quicknav {
    margin-top: 40px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    footer #quicknav a {
      text-align: center;
      font-size: 0.9rem;
      letter-spacing: 7.2px;
      text-indent: 1.8px;
      padding: 0.72em 0.9em;
      text-transform: uppercase;
      padding: 0;
      margin-left: -12px; }
      footer #quicknav a:hover {
        color: #466c90; }

@media print, screen and (min-width: 40em) {
  footer .callout p {
    margin-left: 20px;
    text-align: left; } }

.pf-label {
  position: relative;
  z-index: 2;
  top: 10px;
  left: 10px;
  display: inline-block;
  vertical-align: top;
  background-color: #e9e9e9;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0 5px;
  letter-spacing: 2px; }

.pf-title {
  text-align: center;
  line-height: 90%;
  color: #e9e9e9; }

.pf-subtitle {
  font: italic normal bold 100% "Open Sans", sans-serif; }

.pf-item {
  background-size: auto 100%;
  background-position: center;
  transition: background-size 0.2s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
  .pf-item:hover {
    background-size: auto 110%; }

.orbitors {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1; }
  .orbitors .orbitor div {
    height: 4px;
    width: 40px;
    background-color: rgba(70, 108, 144, 0.5);
    margin: 20px 5px; }
  .orbitors .orbitor.itsme div {
    background-color: #174b70; }

#portfolio {
  max-width: none;
  margin-right: -3vw;
  margin-top: 0; }
  @media print, screen and (min-width: 64em) {
    #portfolio .section-text {
      padding-left: 10%; } }
  #portfolio .header-anchor {
    left: 0;
    right: 0;
    margin-left: 40%;
    transition: 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98) !important; }
  #portfolio .orbit-slider {
    display: flex;
    flex-direction: row;
    width: auto; }
    #portfolio .orbit-slider .pf-item {
      position: relative;
      min-width: calc(50vw - 18.5px);
      margin-right: 20px;
      margin-left: 0; }
      @media only screen and (min-width: 1400px) {
        #portfolio .orbit-slider .pf-item {
          min-width: calc(33vw - 13.5px); } }
      @media only screen and (min-width: 1800px) {
        #portfolio .orbit-slider .pf-item {
          min-width: calc(25vw - 20px); } }
  #portfolio .pf-label {
    visibility: hidden; }
  #portfolio .pf-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, transparent 35%);
    background-color: rgba(48, 48, 48, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    transition: background-color 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
    text-shadow: 0 0 3px #303030;
    color: #e9e9e9;
    padding-bottom: 5%; }
    #portfolio .pf-overlay .cbutton, #portfolio .pf-overlay #contactme form .textfield .contact-placeholder, #contactme form .textfield #portfolio .pf-overlay .contact-placeholder {
      position: absolute;
      padding-top: 45%;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      opacity: 0;
      transition: opacity 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    #portfolio .pf-overlay:hover {
      cursor: pointer;
      background-color: rgba(0, 25, 65, 0.6); }
      #portfolio .pf-overlay:hover .cbutton, #portfolio .pf-overlay:hover #contactme form .textfield .contact-placeholder, #contactme form .textfield #portfolio .pf-overlay:hover .contact-placeholder {
        opacity: 1; }
  #portfolio.hover .header-anchor {
    margin-left: calc(40% - 0.4*40px); }
  #portfolio.expended .pf-item {
    pointer-events: auto; }
  #portfolio.expended .pf-label {
    visibility: visible; }
  #portfolio.expended .header.white {
    pointer-events: none;
    visibility: hidden; }
  #portfolio.expended .header-anchor {
    margin-left: 0; }
  #portfolio.expended .pf-overlay:not(:hover) {
    background-color: rgba(0, 25, 65, 0); }
  #portfolio.expended .header,
  #portfolio.expended p:not(.pf-subtitle) {
    transition: opacity 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98);
    opacity: 0; }
  #portfolio.expended .section-image {
    pointer-events: auto;
    padding-left: 0;
    left: -3vw; }
    #portfolio.expended .section-image .readmore.white.cbutton, #portfolio.expended .section-image #contactme form .textfield .readmore.white.contact-placeholder, #contactme form .textfield #portfolio.expended .section-image .readmore.white.contact-placeholder {
      left: 0;
      pointer-events: auto; }
      #portfolio.expended .section-image .readmore.white.cbutton img, #portfolio.expended .section-image #contactme form .textfield .readmore.white.contact-placeholder img, #contactme form .textfield #portfolio.expended .section-image .readmore.white.contact-placeholder img {
        transform: scaleX(-1); }
  #portfolio.expended.hover .section-image {
    margin-left: 0; }
  #portfolio.expended .readmore.blue.cbutton, #portfolio.expended #contactme form .textfield .readmore.blue.contact-placeholder, #contactme form .textfield #portfolio.expended .readmore.blue.contact-placeholder {
    background-color: #e9e9e9; }
  #portfolio .orbit-slider-nav {
    position: absolute;
    left: -3vw;
    right: 0;
    height: 100%; }
    #portfolio .orbit-slider-nav .arrows {
      height: 100%;
      width: 100%;
      overflow-x: hidden; }
      #portfolio .orbit-slider-nav .arrows .arrow-left {
        float: left; }
        #portfolio .orbit-slider-nav .arrows .arrow-left.disable {
          left: -40px; }
      #portfolio .orbit-slider-nav .arrows .arrow-right {
        float: right; }
        #portfolio .orbit-slider-nav .arrows .arrow-right.disable {
          left: 40px; }
      #portfolio .orbit-slider-nav .arrows div {
        cursor: pointer;
        position: relative;
        background-color: #e9e9e9;
        z-index: 100;
        color: #0b375b;
        box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
        font-size: 2rem;
        top: calc(50% - 20px);
        height: 40px;
        width: 40px;
        left: 0;
        transition: left 0.2s cubic-bezier(0.34, 0.01, 0.23, 0.98);
        padding: 5px 12px; }
        #portfolio .orbit-slider-nav .arrows div img {
          position: absolute;
          height: 30px; }

#portfolio-small {
  overflow: hidden; }
  #portfolio-small .section-text p {
    margin-bottom: 20px; }
  #portfolio-small .section-image2 {
    width: 85%;
    height: auto;
    margin: 0 auto; }
  #portfolio-small .orbit-slider {
    display: flex;
    flex-direction: row;
    width: 100%;
    transition: margin 0.3s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
  #portfolio-small .pf-item {
    position: relative;
    overflow: visible;
    margin-right: 15px;
    height: 70vw;
    margin-bottom: 100px;
    margin-top: -20px;
    min-width: 100%; }
  #portfolio-small .pf-overlay {
    margin-top: 75vw;
    text-align: center; }
  #portfolio-small .cbutton, #portfolio-small #contactme form .textfield .contact-placeholder, #contactme form .textfield #portfolio-small .contact-placeholder {
    position: absolute;
    top: 0;
    bottom: -120px;
    right: 0;
    left: 0;
    opacity: 0; }
  #portfolio-small .pf-title {
    color: #001941; }
  #portfolio-small .pf-subtitle {
    margin: 0; }
  #portfolio-small .orbit-slider-nav {
    position: relative;
    width: 100%;
    height: auto;
    top: calc(70vw + 20px); }
  #portfolio-small .orbitor div {
    margin: 10px;
    width: 10px; }

.portfoliopages .hero,
.portfoliopages .hero .hero-image {
  height: 50vw;
  max-height: 500px; }

.portfoliopages .buttongroup {
  display: flex;
  justify-content: center; }
  .portfoliopages .buttongroup .cbutton, .portfoliopages .buttongroup #contactme form .textfield .contact-placeholder, #contactme form .textfield .portfoliopages .buttongroup .contact-placeholder {
    margin: 10px;
    overflow: hidden; }
  .portfoliopages .buttongroup .cbutton img, .portfoliopages .buttongroup #contactme form .textfield .contact-placeholder img, #contactme form .textfield .portfoliopages .buttongroup .contact-placeholder img {
    transition: 0.3s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
  .portfoliopages .buttongroup .cbutton.outline:hover img, .portfoliopages .buttongroup #contactme form .textfield .outline.contact-placeholder:hover img, #contactme form .textfield .portfoliopages .buttongroup .outline.contact-placeholder:hover img {
    transform: translateX(-100px); }
  .portfoliopages .buttongroup .cbutton:hover img, .portfoliopages .buttongroup #contactme form .textfield .contact-placeholder:hover img, #contactme form .textfield .portfoliopages .buttongroup .contact-placeholder:hover img {
    transform: translateY(-100px); }

.portfoliopages .parallaxt {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px) scale(2); }

.portfoliopages .hero .boxshadow {
  background: linear-gradient(to bottom, transparent 95%, rgba(0, 0, 0, 0.3) 100%);
  transform: translateZ(0); }

.portfoliopages .hero-overlay {
  position: absolute;
  top: 3vw;
  left: 3vw;
  height: 50vw; }
  .portfoliopages .hero-overlay .cbutton, .portfoliopages .hero-overlay #contactme form .textfield .contact-placeholder, #contactme form .textfield .portfoliopages .hero-overlay .contact-placeholder {
    transition: all 0.3s cubic-bezier(0.34, 0.01, 0.23, 0.98);
    background-color: #e9e9e9;
    color: #0b375b;
    box-shadow: 0 0 10px rgba(23, 75, 112, 0.3); }
    .portfoliopages .hero-overlay .cbutton:hover, .portfoliopages .hero-overlay #contactme form .textfield .contact-placeholder:hover, #contactme form .textfield .portfoliopages .hero-overlay .contact-placeholder:hover {
      padding-left: 50px; }

.portfoliopages .side-detail {
  background-color: #e0e0e0;
  position: sticky;
  top: 30px;
  color: #303030;
  margin: 0 3vw 30px 0;
  padding: 20px;
  overflow-x: hidden; }
  .portfoliopages .side-detail .title {
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 20px;
    line-height: 100%; }
  .portfoliopages .side-detail .detail {
    display: flex;
    margin: 10px 0;
    flex-wrap: nowrap; }
    .portfoliopages .side-detail .detail a,
    .portfoliopages .side-detail .detail a:visited {
      width: 100%;
      display: inline-block;
      color: #303030;
      word-break: break-all; }
    .portfoliopages .side-detail .detail a:hover {
      text-decoration: underline; }
  .portfoliopages .side-detail .detail .left {
    max-height: 30px;
    max-width: 30px;
    height: 100%;
    width: 100%;
    margin: 0 7% 10px 2%; }
  .portfoliopages .side-detail .detail .right {
    margin-top: 4px;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #cacaca; }
    .portfoliopages .side-detail .detail .right * {
      margin-bottom: 10px; }
  .portfoliopages .side-detail .detail .right.lastitem {
    padding-bottom: 0;
    border: none; }
  .portfoliopages .side-detail .skillbar {
    margin-bottom: 15px; }

@media screen and (max-width: 39.9375em) {
  .portfoliopages .side-detail {
    top: 0;
    position: relative;
    padding: 20px 12px 7px 7px;
    margin: 0 auto 20px;
    max-width: calc(350px + 10vw);
    overflow-y: hidden;
    height: auto;
    max-height: 100%;
    transition: 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
    .portfoliopages .side-detail .detail {
      margin: 5px; }
    .portfoliopages .side-detail .detail .left {
      margin: 0 5% 20px;
      min-height: 25px;
      min-width: 25px; }
    .portfoliopages .side-detail .detail .right .skillbar {
      margin-bottom: 10px; }
    .portfoliopages .side-detail .detail .right.lastitem {
      padding-bottom: 40px; }
    .portfoliopages .side-detail.collapse {
      max-height: 200px; }
      .portfoliopages .side-detail.collapse .collapsebutton .showless {
        display: none; }
      .portfoliopages .side-detail.collapse .collapsebutton .showmore {
        display: inline-block; }
  .portfoliopages .collapsebutton {
    z-index: 1;
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 40px 5px 5px;
    background: linear-gradient(to bottom, rgba(224, 224, 224, 0), #e0e0e0 50%);
    text-align: center; }
    .portfoliopages .collapsebutton .showmore {
      display: none; }
  .portfoliopages .buttongroup {
    flex-direction: column-reverse; } }

.project2 {
  margin: 10px; }
  .project2 .pf-item {
    position: relative;
    pointer-events: auto;
    height: 20vw;
    max-height: 250px;
    overflow: hidden; }
    .project2 .pf-item:hover .cbutton, .project2 .pf-item:hover #contactme form .textfield .contact-placeholder, #contactme form .textfield .project2 .pf-item:hover .contact-placeholder {
      opacity: 1; }
  .project2 .pf-title {
    text-align: left;
    font-size: 1.5rem;
    color: #0b375b; }
  .project2 .pf-subtitle {
    color: rgba(97, 97, 97, 0.6); }
  .project2 .pf-overlay {
    width: 100%;
    padding-top: 10px; }
  .project2 .cbutton, .project2 #contactme form .textfield .contact-placeholder, #contactme form .textfield .project2 .contact-placeholder {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 35%;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 25, 65, 0.6);
    transition: opacity 0.5s cubic-bezier(0.34, 0.01, 0.23, 0.98); }
  @media screen and (max-width: 39.9375em) {
    .project2 {
      margin: 5px; }
      .project2 .pf-item {
        height: 35vw; }
      .project2 .pf-label {
        left: 5%;
        top: 5%;
        font-size: 3vw;
        letter-spacing: 1px; }
      .project2 .cbutton, .project2 #contactme form .textfield .contact-placeholder, #contactme form .textfield .project2 .contact-placeholder {
        padding-top: 100%; }
      .project2 .pf-title {
        font-size: 1.2rem;
        margin-bottom: 5px; }
      .project2 .pf-subtitle {
        font-size: 0.8rem; } }

/* Lightbox */
#lightBox {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20000; }

#overlay {
  background: #333;
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 10000; }

#lightBox .container {
  background: #FFF;
  display: inline-block;
  padding: 0.9em;
  position: relative; }

#lightBox .image {
  background: url(../media/lightbox/ajax-loader.gif) no-repeat center center #FFF;
  display: block;
  position: relative;
  z-index: 1; }

#lightBox .image img {
  display: block; }

#lightBox .navigatory {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-position 0.1s linear;
  z-index: 2; }

#lightBox #nextImage {
  background: url(../media/lightbox/right-tab.png) no-repeat 100% center;
  background-position: calc(100% - 1em) center;
  left: 66%; }

#lightBox #nextImage:hover {
  background-position: calc(100% - 0.5em) center; }

#lightBox #nextImage:active {
  background-position: calc(100% - 0em) center; }

#lightBox #prevImage {
  background: url(../media/lightbox/left-tab.png) no-repeat 0 center;
  background-position: calc(0% + 1em) center;
  right: 66%; }

#lightBox #prevImage:hover {
  background-position: calc(0% + 0.5em) center; }

#lightBox #prevImage:active {
  background-position: calc(0% + 0em) center; }

#lightBox .data {
  display: block;
  padding: 0.9em 0 0 0; }

#lightBox .leftData, #lightBox .rightData {
  display: inline-block;
  vertical-align: middle;
  width: 50%; }

#closeBtn {
  background: url(../media/lightbox/close.png) center no-repeat #FFF;
  border-radius: 50%;
  height: 50px;
  position: absolute;
  right: -25px;
  right: calc(-25px - 0.45em);
  top: -25px;
  top: calc(-25px - 0.45em);
  width: 50px;
  z-index: 3; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=85.375em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #e9e9e9;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #303030;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.grid-container {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
  max-width: 75rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 40em) {
    .grid-container {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .grid-container.fluid {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 40em) {
      .grid-container.fluid {
        padding-right: 0.625rem;
        padding-left: 0.625rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.625rem;
      margin-right: -0.625rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 0.625rem);
    margin-left: 0.3125rem;
    margin-right: 0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.25rem);
      margin-left: 0.625rem;
      margin-right: 0.625rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 0.625rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 0.625rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 0.625rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 0.625rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 0.625rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 0.625rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 0.625rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 0.625rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 0.625rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 0.625rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 0.625rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 0.625rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.25rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.25rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.25rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.25rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.25rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.25rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.25rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.25rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.25rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.25rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.25rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.25rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.25rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.25rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.25rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.25rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.25rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.25rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.25rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.25rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.25rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.25rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.25rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.25rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.25rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.25rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.25rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.25rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.25rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.25rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.25rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.25rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.25rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.25rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.25rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.25rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }

.grid-padding-x > .cell {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 0.625rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 0.625rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 0.625rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 0.625rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 0.625rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 0.625rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 0.625rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 0.625rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 0.625rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 0.625rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 0.625rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 0.625rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 0.625rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 0.625rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 0.625rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 0.625rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.25rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.25rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.3125rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.3125rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.3125rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.3125rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.3125rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.3125rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.3125rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.3125rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.3125rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.3125rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.3125rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.3125rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.625rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.625rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.625rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.625rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.625rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.625rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.625rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.625rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.625rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.625rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.625rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.625rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.625rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.625rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.625rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.625rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.625rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.625rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.625rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.625rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.625rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.625rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.625rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.625rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.625rem;
      margin-bottom: -0.625rem; } }

.grid-padding-y > .cell {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem; } }

.grid-margin-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.625rem;
      margin-bottom: -0.625rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 0.625rem);
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.25rem);
      margin-top: 0.625rem;
      margin-bottom: 0.625rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 0.625rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 0.625rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 0.625rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 0.625rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 0.625rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 0.625rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 0.625rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 0.625rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 0.625rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 0.625rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 0.625rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 0.625rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.25rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.25rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.25rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.25rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.25rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.25rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.25rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.25rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.25rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.25rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.25rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.25rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.25rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.25rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.25rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.25rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.25rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.25rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.25rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.25rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.25rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.25rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.25rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.25rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.25rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.25rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.25rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.25rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.25rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.25rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.25rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.25rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.25rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.25rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.25rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.25rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.625rem;
      margin-bottom: -0.625rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 0.625rem);
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.25rem);
      margin-top: 0.625rem;
      margin-bottom: 0.625rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 0.625rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 0.625rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 0.625rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 0.625rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 0.625rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 0.625rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 0.625rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 0.625rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 0.625rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 0.625rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 0.625rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 0.625rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.25rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.25rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.25rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.25rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.25rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.25rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.25rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.25rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.25rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.25rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.25rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.25rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.25rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.25rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.25rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.25rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.25rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.25rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.25rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.25rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.25rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.25rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.25rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.25rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.25rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.25rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.25rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.25rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.25rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.25rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.25rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.25rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.25rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.25rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.25rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.25rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 0.625rem); }
  @media print, screen and (min-width: 40em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.25rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.25rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.25rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.25rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Titillium Web", sans-serif;
  font-style: italic;
  font-weight: 600;
  color: #174b70;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 3.75rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.5625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.5625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 0.75rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

p, .p {
  font-size: 0.875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 5rem; }
  h2, .h2 {
    font-size: 1.875rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 0.875rem; }
  h6, .h6 {
    font-size: 1rem; }
  p, .p {
    font-size: 0.875rem; } }

@media print, screen and (min-width: 64em) {
  h1, .h1 {
    font-size: 6.25rem; }
  h2, .h2 {
    font-size: 1.875rem; }
  h3, .h3 {
    font-size: 2.1875rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1rem; }
  h6, .h6 {
    font-size: 1rem; }
  p, .p {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #0b375b;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #092f4e; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #303030;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e0e0e0;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #303030; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e0e0e0;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #303030; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: "Roboto", sans-serif;
  padding: 1em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: all 0.25s ease-out;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #0b375b;
  color: #e9e9e9; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #092f4d;
    color: #e9e9e9; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #0b375b;
    color: #e9e9e9; }
    .button.primary:hover, .button.primary:focus {
      background-color: #092c49;
      color: #e9e9e9; }
  .button.secondary {
    background-color: #174b70;
    color: #e9e9e9; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #123c5a;
      color: #e9e9e9; }
  .button.success {
    background-color: #409042;
    color: #e9e9e9; }
    .button.success:hover, .button.success:focus {
      background-color: #337335;
      color: #e9e9e9; }
  .button.warning {
    background-color: #d54e54;
    color: #e9e9e9; }
    .button.warning:hover, .button.warning:focus {
      background-color: #bc2d33;
      color: #e9e9e9; }
  .button.alert {
    background-color: #d54e54;
    color: #e9e9e9; }
    .button.alert:hover, .button.alert:focus {
      background-color: #bc2d33;
      color: #e9e9e9; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #0b375b;
      color: #e9e9e9; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #0b375b;
        color: #e9e9e9; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #174b70;
        color: #e9e9e9; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #409042;
        color: #e9e9e9; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #d54e54;
        color: #e9e9e9; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #d54e54;
        color: #e9e9e9; }
  .button.hollow {
    border: 1px solid #0b375b;
    color: #0b375b; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #061c2e;
      color: #061c2e; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #0b375b;
        color: #0b375b; }
    .button.hollow.primary {
      border: 1px solid #0b375b;
      color: #0b375b; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #061c2e;
        color: #061c2e; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #0b375b;
          color: #0b375b; }
    .button.hollow.secondary {
      border: 1px solid #174b70;
      color: #174b70; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #0c2638;
        color: #0c2638; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #174b70;
          color: #174b70; }
    .button.hollow.success {
      border: 1px solid #409042;
      color: #409042; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #204821;
        color: #204821; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #409042;
          color: #409042; }
    .button.hollow.warning {
      border: 1px solid #d54e54;
      color: #d54e54; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #761c20;
        color: #761c20; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #d54e54;
          color: #d54e54; }
    .button.hollow.alert {
      border: 1px solid #d54e54;
      color: #d54e54; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #761c20;
        color: #761c20; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #d54e54;
          color: #d54e54; }
  .button.clear {
    border: 1px solid #0b375b;
    color: #0b375b; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #061c2e;
      color: #061c2e; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #0b375b;
        color: #0b375b; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #0b375b;
      color: #0b375b; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #061c2e;
        color: #061c2e; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #0b375b;
          color: #0b375b; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #174b70;
      color: #174b70; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #0c2638;
        color: #0c2638; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #174b70;
          color: #174b70; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #409042;
      color: #409042; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #204821;
        color: #204821; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #409042;
          color: #409042; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #d54e54;
      color: #d54e54; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #761c20;
        color: #761c20; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #d54e54;
          color: #d54e54; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #d54e54;
      color: #d54e54; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #761c20;
        color: #761c20; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #d54e54;
          color: #d54e54; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #e9e9e9 transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #0b375b; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #0b375b; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #174b70; }
  .button.dropdown.hollow.success::after {
    border-top-color: #409042; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #d54e54; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #d54e54; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #e9e9e9;
  box-shadow: inset 0 1px 2px rgba(48, 48, 48, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #303030;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #e9e9e9;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e0e0e0;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #303030; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #303030; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e0e0e0;
  color: #303030;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #e9e9e9;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #303030;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #e9e9e9;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #d54e54;
  background-color: #e7dada; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #d54e54; }

.is-invalid-label {
  color: #d54e54; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #d54e54; }
  .form-error.is-visible {
    display: block; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 1rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #0b375b;
    color: #e9e9e9; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #092c49;
      color: #e9e9e9; }
  .button-group.secondary .button {
    background-color: #174b70;
    color: #e9e9e9; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #123c5a;
      color: #e9e9e9; }
  .button-group.success .button {
    background-color: #409042;
    color: #e9e9e9; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #337335;
      color: #e9e9e9; }
  .button-group.warning .button {
    background-color: #d54e54;
    color: #e9e9e9; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #bc2d33;
      color: #e9e9e9; }
  .button-group.alert .button {
    background-color: #d54e54;
    color: #e9e9e9; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #bc2d33;
      color: #e9e9e9; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 0px solid rgba(48, 48, 48, 0.25);
  border-radius: 0;
  background-color: #e9e9e9;
  color: #303030; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #0b375b;
    color: #e9e9e9; }
  .callout.secondary {
    background-color: #174b70;
    color: #e9e9e9; }
  .callout.success {
    background-color: #409042;
    color: #303030; }
  .callout.warning {
    background-color: #d54e54;
    color: #e9e9e9; }
  .callout.alert {
    background-color: #d54e54;
    color: #e9e9e9; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #303030; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 85.3125em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 85.375em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }
