$nav-offset: 15px;
$nav-width: 300px;
$list-items: 5;


.navigation {
  z-index: 1000;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  //height: 100%;
  //transition: transform 0.5s $timingcurve;
  @keyframes wave {
    0% {
      padding-right: $nav-offset;
    }

    100% {
      padding-right: $nav-offset+10px;
    }
  }

  #burger {
    pointer-events: all;
    position: fixed;
    top: 0;
    right: 0;
    width: 100px;
    height: 100px;
    z-index: 999;
    background: radial-gradient(at 70px 30px, rgba($white, 0.7) 0%, rgba($white, 0) 60%);

    svg {
      float: right;
      margin-top: $nav-offset;
      margin-right: $nav-offset;
    }
    svg line {
      stroke-width: 3;
      stroke: $blue2;
      transform: translateX(40px);
      transition: transform 0.4s $timingcurve, opacity  0.2s $timingcurve;
    }
    @for $j from 1 through 3 {
      .line-#{$j} {
        transition-delay: 0.2s + $j*0.08s;
      }
    }
  }

  #nav-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    opacity: 0.3;
    transition: opacity 0.5s $timingcurve;
  }

  #list,
  #nav-background {
    //visibility: collapse;
  }

  #list {
    position: absolute;
    top: $nav-offset;
    right: $nav-offset;
  }

  #nav-background {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: $nav-width;
    overflow: hidden;
    background-color: $white;
    box-shadow: 5px 0 20px rgba($blue2, 0.4);
    transition: 0.5s $timingcurve;
  }

  .logo {
    height: 20vh;
    width: $nav-width;
    object-fit: contain;
    padding-top: 20px;
    padding-left: 10px;
    transition: 0.5s $timingcurve 0.8s;
  }

  #linkedin {
    height: 50px;
    position: absolute;
    bottom: 5vh;
    right: $nav-offset+10px;
    transition: opacity 0.5s $timingcurve 0.8s, fill 0.2s $timingcurve 0s;
    fill: rgba($gray1,0.5);

    &:hover {
      fill: $blue4
    }
  }

  #list {
    right: 0;
    margin-top: 20vh;
    //min-height: 60px*$list-items;
    //max-height: 80px*$list-items;
    //height: auto;
    display: flex;
    width: $nav-width; //width: 100%;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
    @for $i from 1 through $list-items {
      .navitem-#{$i} {
        transition: padding-right 0.4s $timingcurve 0.2s+$i*0.08s, background-color 0.3s $timingcurve, transform 0.4s $timingcurve, opacity 0.4s $timingcurve 0.2s+$i*0.08s;
      }
    }

    li {
      text-align: right;
      font-family: $button-font-family;
      text-transform: uppercase;
      list-style-type: none;
      @include buttonscaler(1);
      word-spacing: -10px;
      width: $nav-width + 50px;
      transform: translate(0,0);
      padding: 3vh $nav-offset+50px 3vh 0;

      &:hover {
        transform: translateX(-5px);
        transition-delay: 0;
        //letter-spacing: 12px;
        background-color: $light-gray;
        //background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 0.4) 60%);
      }

      &.thispage {
        background-color: $blue4;

        a {
          color: $white;
        }
      }
    }
  }

  &.unactive {
    pointer-events: none;

    #burger line {
      transform: translate(0);
    }

    .logo, #linkedin {
      opacity: 0;
      transition: opacity 0.01s 0.5s;
      //transition-delay: 0;
    }

    #nav-background {
      //opacity: 0;
      transition-delay: 0s;
      border-width: 0;
    }

    #list li {
      //transform: translateX(50px);
      padding-right: 0;
      transition-delay: 0.2s;
      opacity: 0;
    }

    #nav-overlay {
      opacity: 0;
    }
  }
}

@include breakpoint(medium) {
  .navigation.unactive #nav-background {
    //width: 0;
    transform: translateX($nav-width);
  }
}
@include breakpoint(small only) {
  .navigation {

    #burger {
      background: transparent;
      svg {
        height: 30px;
        margin-right: 5px;
        margin-top: 5px;

        line {
          transition-duration: 0.2s;
          transition-delay: 0.3s !important;
        }

        .line-1 {
          transform-origin: 50% 8px;
          transform: translateY(12px) rotate(-45deg);
        }

        .line-2 {
          opacity: 0;
        }

        .line-3 {
          transform-origin: 50% 32px;
          transform: translateY(-12px) rotate(45deg);
        }
      }
      transform: translateY(calc(85vh - 40px));
      transition: transform 0.5s $timingcurve;
    }

    #nav-background {
    top: 0;
    width: 100%;
    height: 85vh;

      .logo {
        height: 100px;
        width: 100%;
        padding-left: 5px;
        transition-delay: 0s;
      }

      #linkedin {
        height: 30px;
        right: auto;
        left: 5px;
        bottom: 5px;
      }

      #list {
        width: 100%;
        margin: 100px 0 40px;
      }

      #list li {
        padding: 2.3vh 0;
        text-align: center;
        width: 100%;
        text-indent: 5px;

        &:hover {
          transform: translateX(0);
        }
      }
    }
    &.unactive {
      #burger {
        transform: translateY(0);

        svg line {
           transform: translate(0, 0) rotate(0deg);
           opacity: 1;
        }
      }

      #nav-background {
        //opacity: 0;
        height: 40px;
        //transform: translateY(calc(-85vh + 40px));
        .logo {
          //opacity: 1;
          //height: 40px;
          //padding-top: 2px;
        }
      }
    }
  }
}
