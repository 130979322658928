body {
  overflow-x: hidden;
}

@keyframes pulselogo {
  0%, 25%, 50% {
    transform: translateY(0);
  }
  12%, 37% {
    transform: translateY(10px);
  }
}

@keyframes subtitleup {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-70px);
  }
}

@keyframes subtitleup_small {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50px);
  }
}

@keyframes scrollanim {
  0%, 80% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
}

.herowrapper {
  height: 90vh;
  //overflow-y: hidden;
  margin: auto;
  max-width: $global-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.herobox {
  min-width: 100%;
  margin: auto;

  .herologo {
    position: relative;
    //animation: pulselogo 4s 2s infinite $timingcurve;
    max-width: 800px;
    margin: 100px auto 0;

    #tfwsplash, #tfwsplash-back {
      backface-visibility: hidden;
      transition: all 0.6s $timingcurve 0.3s;
    }

    #tfwsplash-back {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transform: rotate3d(0,1,0,180deg);
    }

    &:not(.fliplocked):hover {
      #tfwsplash {
        margin: 70px 0;
        transform: rotate3d(0,1,0,180deg);
      }
      #tfwsplash-back {
        margin: 70px 0;
        transform: rotate3d(0,0,0,0deg);
      }
    }
  }

  .subtitle {
    width: auto;
    text-align: center;
    font-family: $button-font-family;
    text-transform: uppercase;
    font-size: 200%;
    letter-spacing: 15px;
    color: $secondary-color;
    text-indent: 8px;
    animation: subtitleup 0.8s $timingcurve 2.8s;
    animation-fill-mode: both;
  }

  #intro {
    max-width: 800px;
    margin: 120px auto 20px;

    .cbutton {
      width: 100%;
      overflow-x: hidden;

      &:hover {
        letter-spacing: 15px;
      }
    }

    #mywork {
      background-color: transparent;
      color: $primary-color;
      border: 1px solid $primary-color;
    }
  }
}

#scrollanimation {
  position: static;
  display: block;
  margin: 50px auto;
  animation: scrollanim 2s $timingcurve infinite;
  stroke: $blue4;
  stroke-width: 3;
  stroke-linecap: round;
  opacity: 0.5;
}

.content-wrapper {
  margin: -100px $content-margin 0;
  overflow-x: visible;
  min-width: 0;

  &.extended {
    transition: all 0.3s $timingcurve;
    margin: -100px 0 0;
    min-width: 100%;
  }
}
@function get-left-alignment() {
  $var: calc(100vw)*1px;
  @if $var > $global-width {
     @return (100vw - $global-width)/2;
  }
  @else {
     @return 0;
  }
}
@function whichside($alignment,$left,$right) {
   @if $alignment == left{
    @return $left;
  }
  @else {
     @return $right;
  }
}
@mixin section-style($height, $division, $alignment, $headeroffset) {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 100px auto;
  height: $height;
  @include breakpoint(medium only) {
    height: $height*0.9;
  }
  max-width: $global-width;
  flex-direction: whichside($alignment,row,row-reverse);

  .p-wrapper > * {
    transition: opacity $ahover-spd $timingcurve;
  }

  &.hover {
    .section-image {
      //clip-path: inset(whichside($alignment,0 40px 0 0,0 0 0 40px));
      margin-left: whichside($alignment,-40px,40px);

      .header.white {
        margin-left: whichside($alignment,40px,-40px);
      }
    }

    .p-wrapper > * {
      opacity: 0.5;
    }

  }

  .section-text {
    text-align: whichside($alignment,right,left);
    width: $division;
    margin: auto 0;
    padding-bottom: 50px;

    .dummy {
      opacity: 0;
    }

    .p-wrapper {
      margin: whichside($alignment,0 20px 40px 0,0 0 40px 20px);
    }
  }

  .readmore {
    &.cbutton, {
      line-height: 38px;
      height: 40px;
      //vertical-align: middle;
      margin: whichside($alignment,0 -40px 0 0,0 0 0 -40px);
      padding: whichside($alignment,0 54px 0 20px,0 20px 0 56px); //54 = 60-6 vanwege letter spacing aan rechterkant
    }

    &.white.cbutton {
      position: absolute;
      width: 40px;
      display: inline-flex;
      left: whichside($alignment,0,auto);
      right: whichside($alignment,auto,0);
      white-space: nowrap;
      box-shadow: whichside($alignment,2px,-2px) 3px 4px rgba(0,0,0,.25);
      padding: 5px;
      margin: 0;
      color: $primary-color;
      background-color: $white;

      img {
        margin: auto;
        height: 30px;
        transform: rotate(whichside($alignment,0deg,-180deg));
      }
    }
  }

  .header {
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.blue {
      text-align: $alignment;
      left: whichside($alignment,$headeroffset,auto);
      right: whichside($alignment,0,$headeroffset);
    }

    &.white {
      z-index: 1;
      color: $white;
      text-shadow: whichside($alignment,2px,-2px) 3px 4px rgba(0,0,0,.25);
      transition: margin $ahover-spd $timingcurve;
    }
  }

  .section-image {
    float: $alignment;
    z-index: 0;
    pointer-events: none;
    padding: whichside($alignment,0 0 0 $division,0 $division 0 0);
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .img {
      pointer-events: none;
      position: relative;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-color: $gray1;
      width: auto;
      height: 100%;
      transition: transform 0.5s $timingcurve;
    }

    .img * {
      pointer-events: auto;
    }

    .frame {
      transition: margin $ahover-spd $timingcurve;
    }
  }

  &.fallback {
    .section-image {
      transition: none;
      left: whichside($alignment,$division,0);
      right: whichside($alignment,0,$division);
      padding: 0;
      width: auto;

      &.hover {
        padding: 0;

        .img {
          transform: scale(1.02);
        }
      }

      .readmore.white {
        //margin-left: whichside($alignment,0px,30px);
      }
    }
  }
  //ANIMATE SECTION-WRAPPER OUT - IN:
  &.element-out {
    .header {
      transform: translateX(whichside($alignment,-100px,100px));
      opacity: 0;
    }

    .section-image {
      //transform: translateX(whichside($alignment,50px,-50px));
      //opacity: 0;
    }

    .readmore,
    .p-wrapper {
      transform: translateY(70px);
      opacity: 0;
    }
  }

  &.element-in {
    .header,
    .readmore,
    .section-image,
    .p-wrapper {
      transform: translateX(0px);
      opacity: 1;
    }

    .section-image {
      transition: transform $ain-spd $timingcurve, opacity $ain-spd $timingcurve, padding $ahover-spd $timingcurve, margin $ahover-spd $timingcurve;
    }

    .p-wrapper {
      transition: transform $ain-spd $timingcurve $ain-spd, opacity $ain-spd $timingcurve $ain-spd, color $ahover-spd $timingcurve 0s;
      //transition-delay: $ain-spd;
    }

    .readmore {
      transition: transform $ain-spd $timingcurve $ain-spd + 0.15s, opacity $ain-spd $timingcurve $ain-spd + 0.15s, $alignment $ahover-spd $timingcurve 0s;
    }

    .header {
      transition: transform $ain-spd $timingcurve $ain-spd - 0.15s, opacity $ain-spd $timingcurve $ain-spd - 0.15s, margin $ahover-spd $timingcurve;;
    }

    .vanish {
      transition: opacity 0.15s $timingcurve;
      opacity: 0;
    }
  }

  &.extended {
    transition: max-width 0.5s $timingcurve, height 0.5s $timingcurve;
    height: $hero-image-height;
    min-width: 100%;

    .section-image {
      position: absolute;
      left: 0;
      right: 0;
      padding: 0;
      margin: 0;

      .img {
        transition: margin 0.5s $timingcurve;
        margin: 0;
      }
    }
  }
}
@include breakpoint(medium) {
  #aboutme {
    @include section-style(500px, 40%, left, 20%);
  }

  #skills {
    @include section-style(600px, 50%, right, 15%);
  }

  #portfolio {
    @include section-style(450px, 40%, left, 20%);
  }

  #contactme {
    @include section-style(400px, 40%, right, 10%);
  }
}

.vanish {
  transition: opacity 0.2s $timingcurve;
  opacity: 0;
}
@include breakpoint(large) {
  background-color: #4a4a4a;
}
@include breakpoint(medium only) {
  background-color: green;
}
@include breakpoint(small only) {
  background-color: blue;
}


//BREAKPOINT SMALL
@include breakpoint(small only) {

  .herowrapper {
    height: 60vh;
    //max-width: 95vw;
    //overflow: visible;

    .herobox {
      position: static;
      pointer-events: none;
      //overflow: hidden;
    }

    .herobox .herologo #tfwsplash {
      display: block;
      max-width: 95vw;
      margin: auto;
    }

    .herobox .herologo #tfwsplash-back {
      transform: rotate3d(0,0,0,0deg) translateY(-25vw);
      opacity: 0.05;
      height: 110vw;
      object-fit: cover;
    }

    .subtitle {
      margin-top: 10px;
      font-size: 4.5vw;
      letter-spacing: 2vw;
      animation-name: subtitleup_small;
    }

    #mainbuttons {
      margin-top: 50px;
      max-width: 400px;
    }
  }

  #scrollanimation {
    width: 40px;
    margin: -10px auto 10px;
  }

  .content-wrapper {
    margin: 0;
  }

  .section-wrapper {
    margin-top: 50px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    .dummy,
    .header.blue,
    .readmore img,
    .readmore.white {
      display: none;
    }

    .section-image {
      width: 100%;
      height: 250px;
      position: relative;

      .img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
      }

      .ishadow {
        box-shadow: 0 -60px 50px -20px $black inset;
      }
    }

    .header.white {
      //wordt vervolgens white
      position: absolute;
      z-index: 1;
      color: $white;
      text-shadow: 0px 2px 5px rgba(0,0,0,.6);
      line-height: 90%;
      bottom: 10px;
      text-align: center;
      width: 100%;
    }

    p {
      margin: 20px 15px 50px;
      text-align: center;
    }

    .centerme {
      display: block;
      text-align: center;

      .readmore.blue {
        max-width: 300px;
      }
    }
    &.element-out:not(#aboutme) {
      transform: translateY(100px);
      opacity: 0;
    }

    &.element-in {
      transform: translateY(0);
      opacity: 1;
      transition: $ain-spd $timingcurve 0.5s;
    }
  }

  #skills p {margin-bottom: 10px}
}
