footer {
  margin: 80px 5vw 40px 5vw;

  #footer-wrapper {
    border-top: 1px solid $medium-gray;
  }

  .callout {
    background-color: $blue4;
    color: $white;
    //text-align: center;
    display: flex;
    img {
      width: 60px;
      height: auto;
    }
    p {
      color: $white;
    }
    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  p {
    text-align: center;
    color: $dark-gray;
    font-size: 95%;
  }

  #footerlogo {
    display: block;
    margin: 0px auto 0px;
    width: 250px;
    height: auto;
    filter: grayscale(1);
    opacity: 0.1;
  }

  #socialwrapper {
    display: flex;
    justify-content: center;
    margin: 15px;
    #linkedin {
      width: 40px;
      fill: rgba($gray1, 0.2);
      transition: 0.2s $timingcurve;
      &:hover {
        fill: $blue4;
      }
    }
  }

  #quicknav  {
    margin-top: 40px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    a {
      text-align: center;
      @include buttonscaler(0.9);
      text-transform: uppercase;
      padding: 0;
      margin-left: -12px;

      &:hover {
        color: $blue4;
      }
    }
  }
}

@include breakpoint(medium) {
  footer .callout p {
    margin-left: 20px;
    text-align: left;
  }
}
