//PORTFOLIO ITEMS

.project2 {
  margin: 10px;

  .pf-item {
    position: relative;
    pointer-events: auto;
    height: 20vw;
    max-height: 250px;
    overflow: hidden;

    &:hover {
      .cbutton {
        opacity: 1;
      }
    }
  }

  .pf-title {
    text-align: left;
    font-size: 1.5rem;
    color: $blue2;
  }

  .pf-subtitle {
    color: rgba($gray1,0.6);
  }

  .pf-overlay {
    width: 100%;
    padding-top: 10px;
    //height: 100px;
  }

  .cbutton {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 35%;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba($blue1,0.6);
    transition: opacity $ahover-spd $timingcurve;
  }

  @include breakpoint(small only) {
    margin: 5px;
    .pf-item {
      height: 35vw;
    }

    .pf-label {
      left: 5%;
      top: 5%;
      font-size: 3vw;
      letter-spacing: 1px;
    }

    .cbutton {
      padding-top: 100%;
    }

    .pf-title {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }

    .pf-subtitle {
      font-size: 0.8rem;
    }
  }
}
