@import 'masonry';

$max-body-width: 700px;
@keyframes heroshadow {
  from {
    opacity: 0
  }

  to {
    opacity: 1;
  }
}

.mainpages,
.portfoliopages {

  .hero-image {
    position: fixed;
    z-index: -1;
  }

  .sideicon-wrapper {
    pointer-events: none;
    position: absolute;
    top: $hero-image-height;
    margin-top: 100px;
    height: 100vh;
    width: 33%;

    .sideicon {
      position: sticky;
      //z-index: -1;
      top: 80px;
      left: 0;
      width: 100%;
      padding: 25px 50px;
      transition: opacity $ain-spd $timingcurve;
      opacity: 0.07;
      &.element-out {
        opacity: 0;
      }
    }
  }



  .content-wrapper {
    margin: auto;
    padding: 20px;
    padding-top: 50px;
    max-width: $global-width;
    height: auto;


    .bodytext {
      line-height: 180%;
      max-width: $max-body-width;
      //margin-bottom: 30px;
    }
  }

  .hero {
    position: relative;
    width: 100%;
    height: $hero-image-height;
    overflow: hidden;

    .boxshadow {
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      //background-color: red;
      animation: heroshadow 0.5s $timingcurve;
      background: linear-gradient(to bottom, rgba(black,0)  50%, rgba(black,0.7)  100%);
    }

    .hero-image {
      background-color: $gray1;
      width: 100%;
      height: $hero-image-height;
      object-fit: cover;
      object-position: center;

    }

    .hero-details {
      position: absolute;
      bottom: 120px;
      margin-bottom:-2rem;
      width: 100%;
      text-align: center;
      color: $white;
      font: italic normal normal 2rem $body-font-family;
    }

    .header.white {
      color: $white;
      text-shadow: 5px 8px 10px rgba(0,0,0,.25);
    }

    .header.blue {
      color: $blue3;
    }
  }
}

.mainpages {
  .header {
    position: absolute;
    text-align: center;
    direction: rtl;
    width: 100%;
    white-space: nowrap;
    top: calc(90vh - 380px);
    width: 100%;
    max-width: 1200px;
    margin-left: 0;
    padding: 25px;
    font-size: 10rem;
    @include breakpoint(medium down) {
      transform: translateY(90px);
      font-size: 6rem;
    }
    transition-duration: 0.7s;
    transition-timing-function: $timingcurve;
    transition-property: font-size, transform, opacity, max-width;

    &.toside {
      height: auto;
      font-size: 4rem;
      transform: translateY(380px);
      max-width: 33%;
    }
  }

  .header.blue {
    z-index: -5;
  }

  .fixed {
    position: fixed;
    bottom: auto;
    top: -400px;
  }


  .pf-overview {
    max-width: $max-body-width;
  }

  h3 {
    color: $medium-gray;
    margin-top: 30px;
    //margin-left: 5px;
  }

  .skillpies {
    h5 {
      text-align: center;
      margin-bottom: 12px;
    }
  }

}

@media only screen and (min-width: 1200px) {
  .mainpages .header {
    margin-left: calc((100% - 1200px) / 2);
    &.toside {
      max-width: 400px;
    }
  }

  .mainpages .sideicon-wrapper {
    left: calc((100% - 1200px) / 2);
    width: 400px;
  }
}

//BREAKPOINT SMALL
@include breakpoint(small only) {
  .mainpages,
  .portfoliopages {
    margin-top: 40px; //voor de navbar

    .content-wrapper {
      padding-top: 20px;
    }
  }

  .mainpages {
    .boxshadow {
        background: linear-gradient(to bottom, rgba(black,0)  30%, rgba(black,0.7)  100%);
    }
    .hero, .hero .hero-image {
      height: 50vh;

      .header-small {
        position: absolute;
        text-align: center;
        width: 100%;
        color: $white;
        text-shadow: 5px 8px 10px rgba(0,0,0,.25);
        bottom: 30px;
      }

      .hero-details {
        bottom: 50px;
        font-size: 1.2rem;
      }
    }

    .pf-overview {
      width: 100%;
    }

    .skillpies h5 {
      font-size: 60%;
    }
  }
}
