$timingcurve: cubic-bezier(.34,.01,.23,.98);
$hero-image-height: 90vh;
$ain-spd: 0.7s;
$ahover-spd: 0.5s;
$content-margin: 3vw;

.flyin {
  opacity: 0;

  &:not(.fadeonly) {
    transform: translateY(100px);
  }
}

.flyin-activated {
  opacity: 1;
  transform: translateY(0px);
  transition: transform 0.8s $timingcurve, opacity 0.8s $timingcurve;
}

.clickthru {
  pointer-events: none;
}
//Custom Buttons
@mixin buttonscaler($scalefactor) {
  font-size: 1rem * $scalefactor;
  letter-spacing: 8px * $scalefactor;
  text-indent: 2px * $scalefactor;
  padding: 0.8em * $scalefactor 1em * $scalefactor;
}

.cbutton {
  @include button;
  text-transform: uppercase;
  transition-property: background-color, letter-spacing, color, padding;
  transition-duration: 0.25s;
  transition-timing-function: $timingcurve;
  white-space: nowrap;
  @include buttonscaler(1);
  @include breakpoint(large) {
    &.large {
      @include buttonscaler(1.25);
    }
  }
  @include breakpoint(medium down) {
     @include buttonscaler(0.9);

    &.large {
      @include buttonscaler(1.2);
    }
  }

  &.outline {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;

    &.inverted {
      color: $primary-color;
      border: 1px solid $primary-color;
    }
  }

  &:hover {
    background-color: $secondary-color;
    color: $white;

    &.inverted {
      color: $white;
    }
  }

  img {
    margin-right: 10px;
    margin-top: -5px;
    height: 15px;
    transform: scale(1.5);
  }
}

.pagebg {
  background-color: $white;
}

.test {
  border: 2px dashed red;
}

.h-alt {
  font-weight: 400;
  font-style: normal;
  font-family: $body-font-family;
}

h5 {
  font-style: normal !important;
  font-weight: 400 !important;
}

@keyframes skill-fillbar {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.tag-font p, .tag-font h5 {
  font-size: 90%;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.9;
  margin: 0 0 5px;
  font-family: $body-font-family;
  line-height: 120%;

  @include breakpoint(medium down) {
    font-size: 80%;
  }
}

.skillbar {
  height: 6px;
  margin: 0 0 12px;
  background-color: rgba($black,0.2);
  overflow-x: hidden;

  .fill {
    height: 100%;
    background-color: rgba($blue4,0.7);
    border-right: 5px solid $blue2;
  }

  &.element-out .fill {
    transform: translateX(-100%);
  }

  &.element-in .fill {
    animation: skill-fillbar 1.5s cubic-bezier(.29,.59,.34,1);
  }
}

.skillpie {
  //max-width: 150px;
  margin: 0 4%;
  opacity: 1;
  position: relative;
  //transition: opacity $ain-spd $timingcurve $ain-spd, transform $ain-spd $timingcurve $ain-spd;

  svg {
    width: 100%;
    transform: rotate(-90deg);

    .fill {
      filter: drop-shadow(0 0 3px $medium-gray);
    }

    .stroke {
      $circumference: 283;
      stroke-dasharray: $circumference $circumference;
      stroke-width: 8px;
      transition: stroke-dashoffset 2s $timingcurve;
      transition-delay: 1s;
    }
  }

  img {
    padding: 20%;
    height: 95%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.element-out .skillpie {
  //transform: translateY(50px);
  //opacity: 0;

  svg .stroke {
    stroke-dashoffset: 252;
    //stroke-dasharray: 0 252;
    transition: stroke-dashoffset 2s $timingcurve;
  }
}

.lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($black, 0.9);
  z-index: 1100;
  transition: 0.4s $timingcurve;
  width: 100%;

  .lightbox-itemwrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    transition: 0.4s $timingcurve;
    img {
      margin: auto;
      max-height: 90vh;
      width: 100vw;
      max-width: 100%;
      padding: 20px;
      object-fit: scale-down;
      cursor: zoom-out;
    }
    p {
      margin: auto;
      text-align: center;
      max-width: 800px;
      color: $white;
    }
  }

  &.out {
    opacity: 0;

    .lightbox-itemwrapper {
      transform: translateY(300px);
    }
  }

  &.nocaption .lightbox-itemwrapper img{
    max-height: 100vh;
  }
}

@keyframes fade {
  0% {opacity: 0}
  100% {opacity: 1}
}
.no-jss .nojspage {
  z-index: 10000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: fade 0.1s 0.5s;
  animation-fill-mode: both;
}

.errorpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $white;
  text-align: center;
  p {
    width: 100%;
  }

  .bigger {
    font-size: 130%;
    margin-bottom: 20px;
  }
}

.nojspage {
  display: none;
}

.no-msie .msie.warning {
  display: none !important;
}
